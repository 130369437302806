import React, { Component } from 'react';
import { 
      Button, Col, Form, FormFeedback, FormGroup, FormText,
      Input, Label, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import FormValidator from '../../../services/FormValidator';
import passwordValidator from 'password-validator';
import StatePicker from '../../Shared/StatePicker';
import { TextMask, InputAdapter } from 'react-text-mask-hoc';
import PalladientComponent from '../../../PalladientComponent';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const propTypes = {
    enrollment: PropTypes.object,
    onFormCompleted: PropTypes.func,
}

class NewCustomerInfoPanel extends PalladientComponent {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
              field: 'companyName',
              method: 'isEmpty',
              validWhen: false,
              message: 'You must enter a company name'
            },
            {
              field: 'firstName',
              method: 'isEmpty',
              validWhen: false,
              message: 'Please enter your first name'
            },
            {
              field: 'lastName',
              method: 'isEmpty',
              validWhen: false,
              message: 'Please enter your last name'
            },
            {
              field: 'title',
              method: 'isEmpty',
              validWhen: false,
              message: 'Please enter your title'
            },
            {
              field: 'addressLine1',
              method: 'isEmpty',
              validWhen: false,
              message: 'Address cannot be empty'
            },
            {
              field: 'city',
              method: 'isEmpty',
              validWhen: false,
              message: 'Address must have a city'
            },
            {
              field: 'zip',
              method: 'isEmpty',
              validWhen: false,
              message: 'Please enter 5 digit zip code'
            },
            {
              field: 'password',
              method: this.passwordStrength,
              validWhen: true,
              message: 'Please choose a stronger password'
            },
            {
              field: 'passwordConfirm',
              method: 'isEmpty',
              validWhen: false,
              message: 'You must re-type your password here'
            },
            {
              field: 'passwordConfirm',
              method: this.passwordMatch, // custom function
              validWhen: true,
              message: 'The passwords do not match'
            }
          ]);
  
        this.defineState({
            firstName: this.props.enrollment ? this.props.enrollment.firstName : '',
            lastName: this.props.enrollment ? this.props.enrollment.lastName : '',
            companyName: this.props.enrollment ? this.props.enrollment.companyName : '',
            title: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            zip: "",
            phone: "",
            password: "",
            passwordConfirm: "",
            goLiveDate: this.props.enrollment ? this.props.enrollment.goLiveDate : moment().add(30, "days").format('MM-DD-yyyy'),  
            completed: false,
            validation: this.validator.valid(),
        });

        this.submitted = false;
    }

    passwordMatch = (confirmation, state) => (state.password === confirmation);

    passwordStrength = (password) => {
      var schema = new passwordValidator();
      schema
        .is().min(8)
        .is().max(100)
        .has().uppercase()
        .has().lowercase()
        .has().digits()
        .has().not().spaces();
      return schema.validate(password);
    };

    handleNextClicked() {
        // validate
        var validation = this.validator.validate(this.state);
        this.submitted = true;
        if (validation.isValid) {
            if (this.props.onFormCompleted) {
                this.props.onFormCompleted({
                    password: this.state.password,
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,                
                    companyName: this.state.companyName,
                    title: this.state.title,
                    addressLine1: this.state.addressLine1,
                    addressLine2: this.state.addressLine2,
                    city: this.state.city,
                    state: this.state.state,
                    zip: this.state.zip,
                    phone: this.state.phone, 
                    goLiveDate: this.state.goLiveDate,                             
                });
            }
        }
        else {
            this.setState({validation:validation});
        }      
    }
  
    handleAddressStateChanged(stateData) {
        this.setState({state: stateData.abbreviation});
    }
 
    handleGoLiveDateChanged(date) {
        this.setState({goLiveDate: moment(date).format('MM-DD-yyyy')});
    }
 
    render() {
        let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation;
        return (
            <Form disabled={!this.state.formEnabled}>
                <FormGroup>
                    <Label>Company/Agency Name</Label>
                    <InputGroup controlid="companyName">
                        <Input type="text" id="companyName" placeholder="Enter Company Name" value={this.state.companyName} onChange={this.handleChange} invalid={validation.companyName.isInvalid}/>
                        <FormFeedback valid={false}>{validation.companyName.message}</FormFeedback>
                    </InputGroup>
                </FormGroup>
                <FormGroup row>                                        
                    <Col xs={6}>
                        <Label>First Name</Label>
                        <InputGroup controlid="firstName">
                            <Input type="text" id="firstName" placeholder="First" value={this.state.firstName} onChange={this.handleChange} invalid={validation.firstName.isInvalid}/>
                            <FormFeedback valid={false}>{validation.firstName.message}</FormFeedback>
                        </InputGroup>
                    </Col>
                    <Col xs={6}>
                        <Label>Last Name</Label>
                        <InputGroup controlid="lastName">
                            <Input type="text" id="lastName" placeholder="Last" value={this.state.lastName} onChange={this.handleChange} invalid={validation.lastName.isInvalid}/>
                            <FormFeedback valid={false}>{validation.lastName.message}</FormFeedback>
                        </InputGroup>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Label>Title</Label>
                    <InputGroup controlid="title">
                        <Input type="text" id="title" placeholder="Enter your title" value={this.state.title} onChange={this.handleChange} invalid={validation.title.isInvalid}/>
                        <FormFeedback valid={false}>{validation.title.message}</FormFeedback>
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <Label>Phone</Label>
                    <InputGroup controlid="phone">
                        <InputGroupAddon addonType="prepend">
                        <InputGroupText><i className="fa fa-phone"></i></InputGroupText>
                        </InputGroupAddon>
                        <TextMask
                        id="phone"
                        name="phone"
                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        Component={InputAdapter}
                        className="form-control"
                        onChange={this.handleChange}
                        />
                    </InputGroup>
                    <FormText color="muted">
                        ex. (999) 999-9999
                    </FormText>
                </FormGroup>
                <FormGroup>
                    <Label>Address</Label>
                    <InputGroup controlid="addressLine1">
                        <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="icon-location-pin"></i>
                        </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" id="addressLine1" placeholder="Address Line 1" value={this.state.addressLine1} onChange={this.handleChange} invalid={validation.addressLine1.isInvalid}/>
                        <FormFeedback valid={false}>{validation.addressLine1.message}</FormFeedback>
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <InputGroup controlid="addressLine2">
                        <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="icon-location-pin"></i>
                        </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" id="addressLine2" placeholder="Address Line 2" value={this.state.addressLine2} onChange={this.handleChange}/>
                    </InputGroup>
                </FormGroup>
                <FormGroup row>
                    <Col xs={4}>
                        <InputGroup controlid="city">
                            <Input type="text" id="city" placeholder="City" value={this.state.city} onChange={this.handleChange} invalid={validation.city.isInvalid}/>
                            <FormFeedback valid={false}>{validation.city.message}</FormFeedback>
                        </InputGroup>
                    </Col>
                    <Col xs={4}>
                        <InputGroup controlid="state">
                        <StatePicker id="state" className="form-control" onChange={this.handleAddressStateChanged.bind(this)}/>
                        </InputGroup>
                    </Col>
                    <Col xs={4}>
                        <InputGroup controlid="zip">
                            <Input type="text" id="zip" placeholder="Zip" value={this.state.zip} onChange={this.handleChange} invalid={validation.zip.isInvalid}/>
                            <FormFeedback valid={false}>{validation.zip.message}</FormFeedback>
                        </InputGroup>
                    </Col>
                </FormGroup>
                <Row>
                    <Col>
                        <hr className="text-muted"/>
                    </Col>
                </Row>
                <h5>Password</h5>
                <p className="text-muted">
                    In order to protect your privacy, we insist on the use of
                    a strong password. Therefore, make sure the password you choose is at least
                    8 alpha-numeric characters long, contains a mixture of upper and lower case
                    and at least one number.
                </p>
                <FormGroup>
                    <InputGroup controlid="password" className="mb-3">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="icon-lock"></i>
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" id="password" placeholder="New Password" value={this.state.password} onChange={this.handleChange} invalid={validation.password.isInvalid}/>
                        <FormFeedback valid={false}>{validation.password.message}</FormFeedback>
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <InputGroup controlid="passwordConfirm" className="mb-3">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="icon-lock"></i>
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" id="passwordConfirm" placeholder="Re-enter New Password" value={this.state.passwordConfirm} onChange={this.handleChange} invalid={validation.passwordConfirm.isInvalid}/>
                        <FormFeedback valid={false}>{validation.passwordConfirm.message}</FormFeedback>
                    </InputGroup>
                </FormGroup>
                <Row>
                    <Col>
                        <hr className="text-muted"/>
                    </Col>
                </Row>
                <h5>Preferred Go-live Date</h5>
                <p className="text-muted">
                    Please indicate the date by which you would like to "go live". You will begin receiving monthly invoices after
                    your go-live date. Keep in mind that the enrollment process can take as much as 4 weeks to complete, depending
                    on complexity.
                </p>
                <p className="font-weight-bold">
                    Noteable reserves the right to adjust this date as needed to accommodate your site configuration needs. Your
                    partner advocate will notify you if this date needs to be changed.  
                </p>
                <FormGroup>
                    <InputGroup controlid="goLiveDate" className="mb-3">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="icon-calendar"></i>
                            </InputGroupText>
                            <DatePicker id="goLiveDate" dateFormat="MM-dd-yyyy" showMonthDropdown showYearDropdown selected={moment(this.state.goLiveDate).toDate()} onChange={this.handleGoLiveDateChanged.bind(this)} />
                        </InputGroupAddon>
                    </InputGroup>
                </FormGroup>
                <Row>
                    <Col>
                        <hr className="text-muted"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <Button color="primary" block onClick={this.handleNextClicked.bind(this)}>Next</Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}

NewCustomerInfoPanel.propTypes = propTypes;

export default NewCustomerInfoPanel;