import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/apikey',
        RequestEndpoint = '/apikey/request';

class ApiKeyProvider extends ProviderBase {    
    constructor() {
        super(BaseEndpoint);
    }

    getAll() {
        return this._getAll();
    }

    delete(id) {
        return this._delete(id);
    }

    request(data) {
        return this.api.post(RequestEndpoint, JSON.stringify(data));
    }
}

export default ApiKeyProvider;