import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/noteableprogram';

class NoteableTreatmentProgramProvider extends ProviderBase {
    constructor() {
        super(BaseEndpoint);
    }

    getAll(filter) {
        return this._getAll(filter);
    }
}

export default NoteableTreatmentProgramProvider;
