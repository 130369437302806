import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/enrollment',
        StartTrialEndpoint = '/enrollment/trial',
        EnrollmentReminderEndpoint = '/enrollment/{0}/send-reminder';

class CustomerEnrollmentProvider extends ProviderBase {    
    constructor() {
        super(BaseEndpoint);
    }

    getOne(id, includes) {
        return this._getOne(id, includes);
    }

    post(data) {
        return this._post(data);
    }

    patch(id, data) {
        return this._patch(id, data);
    }

    startTrial(id, trialData) {
        return this.api.post(StartTrialEndpoint.format(id), JSON.stringify(trialData));
    }

    sendReminder(id) {
        return this.api.post(EnrollmentReminderEndpoint.format(id));
    }
}

export default CustomerEnrollmentProvider;