import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/subscription';

class SubscriptionProvider extends ProviderBase {    
    constructor() {
        super(BaseEndpoint);
    }

    getOne(id, includes) {
        return this._getOne(id, includes);
    }

    put(id, data) {
        return this._put(id, data);
    }

    delete(id) {
        return this._delete(id);
    }
}

export default SubscriptionProvider;