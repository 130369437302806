import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { UsStates } from '../../services/UsStates';

const propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object,
}

class StatePicker extends Component {

    handleStateSelected(e) {
        var state = UsStates.find(s => s.abbreviation === e.target.value);
        this.props.onChange(state);
    }

    render() {
        return (
            <Input type="select" id={this.props.id} value={this.props.value} onChange={this.handleStateSelected.bind(this)} className={this.props.className} style={this.props.style}>
                {
                    UsStates.map((state, index) => (
                        <option key={state.abbreviation} value={state.abbreviation}>{state.name}</option>
                    ))
                }
            </Input>
        )
    }
}

StatePicker.propTypes = propTypes;

export default StatePicker