const InvoicePaymentMethodEnum = {    
    Unpaid: 'Unpaid',
    DebitAch: 'DebitAch',
    DebitCard: 'DebitCard',
    ManualCheck: 'ManualCheck',
    ManualAch: 'ManualAch',
    ManualCard: 'ManualCard',
    ManualNoPayment: 'ManualNoPayment',
}

export default InvoicePaymentMethodEnum;