import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/invoice',
        CalculateEndpoint = '/invoice/calculate/{0}/month/{1}/year/{2}',
        DeleteManyEndpoint = '/invoice/deletemany',
        QbExportManyCsvEndpoint = '/invoice/qbexportmany/csv',
        QbExportManyIifEndpoint = '/invoice/qbexportmany/iif',
        PayManyEndpoint = '/invoice/paymany',
        SendManyEndpoint = '/invoice/sendmany',        
        GenerateEndpoint = '/invoice/generate',
        MarkViewedEndpoint = '/invoice/{0}/viewed',
        PayOneEndpoint = '/invoice/{0}/pay',
        SendOneEndpoint = '/invoice/{0}/send',
        SendReminderEndpoint = '/invoice/{0}/remind',
        ClearManyEndpoint = '/invoice/clearmany',
        ClearOneEndpoint = '/invoice/{0}/clear',
        NextNumberEndpoint = '/invoice/nextnumber',
        DebitForOneEndpoint = '/invoice/{0}/debit?test={1}',
        GetByNumberEndpoint = '/invoice/bynumber/{0}',
        ReturnOneEndpoint = '/invoice/{0}/return',
        ApplyCreditEndpoint = '/invoice/{0}/applycredit';        

class InvoiceProvider extends ProviderBase {    
    constructor() {
        super(BaseEndpoint);
    }

    getAll(filter, includes) {
        return this._getAll(filter, includes);
    }

    getOne(id, includes) {
        return this._getOne(id, includes);
    }

    getByInvoiceNumber(invoiceNumber, includeCustomer) {
        let url = GetByNumberEndpoint.format(invoiceNumber);
        if (includeCustomer) {
            url += '?include=Customer';
        }
        return this.api.get(url);
    }

    put(id, data) {
        return this._put(id, data);
    }

    delete(id) {
        return this._delete(id);
    }

    calculate(id, month, year) {
        return this.api.get(CalculateEndpoint.format(id, month, year));
    }

    generate(requestData) {
        return this.api.post(GenerateEndpoint, JSON.stringify(requestData));
    }

    deleteMany(ids) {
        return this.api.post(DeleteManyEndpoint, JSON.stringify({invoiceIds: ids}));
    }

    qbExportManyCsv(ids) {
        return this.api.post(QbExportManyCsvEndpoint, JSON.stringify({invoiceIds:ids}));
    }

    qbExportManyIif(ids, importToAccount) {
        return this.api.post(QbExportManyIifEndpoint, JSON.stringify({invoiceIds: ids, importToAccount: importToAccount}));
    }    

    payMany(ids, paymentDetails) {
        return this.api.post(PayManyEndpoint, JSON.stringify({invoiceIds:ids, paymentMethod: paymentDetails.paymentMethod, paymentDate: paymentDetails.paymentDate}));
    }

    sendMany(ids) {
        return this.api.post(SendManyEndpoint, JSON.stringify({invoiceIds:ids}));
    }

    sendOne(id) {
        return this.api.post(SendOneEndpoint.format(id));
    }

    sendReminder(id) {
        return this.api.post(SendReminderEndpoint.format(id));
    }

    clearMany(ids) {
        return this.api.post(ClearManyEndpoint, JSON.stringify({invoiceIds:ids}));
    }    

    markViewed(id) {
        return this.api.patch(MarkViewedEndpoint.format(id));
    }

    markPaid(id, paymentDetails) {
        return this.api.post(PayOneEndpoint.format(id), JSON.stringify(paymentDetails));
    }

    markCleared(id) {
        return this.api.post(ClearOneEndpoint.format(id));
    }

    markReturned(id) {
        return this.api.post(ReturnOneEndpoint.format(id));
    }

    getNextNumber() {
        return this.api.get(NextNumberEndpoint);
    }

    debitOne(id, test) {
        return this.api.post(DebitForOneEndpoint.format(id, test));
    }

    applyCredit(id) {
        return this.api.post(ApplyCreditEndpoint.format(id));
    }
}

export default InvoiceProvider;