import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import './App.scss';
import cookie from 'react-cookies';

// Containers
import { DefaultLayout } from './containers';
// Pages
import { Login, Page404, Page500, RapidEnrollmentPage, Register } from './views/Pages';
import { 
  SigninPage, SignoutPage, ForgotPasswordPage, ResetPasswordPage, 
  CustomerEnrollmentPage, UserInvitationPage, CustomerUserInvitationPage,
  ViewInvoicePage,
} from './views/Pages';


const isAuthenticated = () => {
  var token = cookie.load('token');
  if (token) {
    // keep the session alive. the server is doing the same thing when api calls
    // are made.
    var expire = new Date();
    expire.setTime(expire.getTime()+(30*60*1000));
    cookie.save('token', token, {path: '/', expires: expire});
    return true;
  }
  
  return false;
}

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    !isAuthenticated() 
      ? <Component {...props}/> 
      : <Redirect to='/' />
  )} />
);

const ProtectedRoute = ({ component: Component, path: path, ...rest }) => (
  <Route path={path} {...rest} render={(props) => { 
    return (
      isAuthenticated() 
        ? <Component {...props}/> 
        : <Redirect to={{ pathname: '/signin', state: {requestedPath: props.location.pathname} }}/>
    )
  }} />
);

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/register" name="Register Page" component={Register} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <Route exact path="/signout" name="Signout Page" component={SignoutPage} />
          <Route exact path="/forgotpassword" name="Forgot Password Page" component={ForgotPasswordPage} />
          <Route exact path="/resetpassword" name="Reset Password Page" component={ResetPasswordPage} />
          <Route exact path="/enrollment" name="Customer Enrollment Page" component={RapidEnrollmentPage} />
          <Route exact path="/invitation" name="User Invitation Page" component={UserInvitationPage} />
          <Route exact path="/viewinvoice" name="View Invoice Page" component={ViewInvoicePage} />
          <Route exact path="/invitation/customer" name="Customer User Invitation Page" component={CustomerUserInvitationPage} />
          <AuthRoute exact path="/signin" name="Sign In" component={SigninPage} />                    
          <ProtectedRoute path="/" name="Home" component={DefaultLayout} />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
