import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/noteabletenant',
        ImportEndpoint = '/noteabletenant/import',
        PayersEndpoint = '/noteabletenant/{0}/payers',
        ProgramsEndpoint = '/noteabletenant/{0}/programs',
        SetupEndpoint = '/noteabletenant/{0}/setup',
        StaffEndpoint = '/noteabletenant/{0}/staff',
        ToggleEnabledEndpoint = '/noteabletenant/{0}/toggle-enabled?enabled={1}';

class NoteableTenantProvider extends ProviderBase {    
    constructor() {
        super(BaseEndpoint);
    }

    getOne(id, includes) {
        return this._getOne(id, includes);
    }

    getAll(filter) {
        return this._getAll(filter);
    }

    getNew() {
        return this.api.get(BaseEndpoint + '?onlyNew=true');
    }

    import(tenantIds) {
        return this.api.post(ImportEndpoint, JSON.stringify({tenantIds:tenantIds}));
    }

    getPayersForTenant(tenantId) {
        return this.api.get(PayersEndpoint.format(tenantId));
    }

    addPayersToTenant(tenantId, payers) {
        return this.api.post(PayersEndpoint.format(tenantId), JSON.stringify(payers));
    }

    getProgramsForTenant(tenantId) {
        return this.api.get(ProgramsEndpoint.format(tenantId));
    }

    enrollInPrograms(tenantId, programIds) {
        return this.api.post(ProgramsEndpoint.format(tenantId), JSON.stringify({programIds:programIds}));
    }

    setup(tenantId, programIds, payers) {
        return this.api.post(SetupEndpoint.format(tenantId), JSON.stringify({programIds:programIds, payers:payers}));
    }

    getStaff(tenantId) {
        return this.api.get(StaffEndpoint.format(tenantId));
    }

    toggleEnabled(tenantId, enabled) {
        return this.api.patch(ToggleEnabledEndpoint.format(tenantId, enabled));
    }
}

export default NoteableTenantProvider;