import React, { Component } from 'react';
import PalladientComponent from '../../../PalladientComponent';
import PropTypes from 'prop-types';
import { FormGroup, FormFeedback, Label, 
    Form, Input, InputGroup, InputGroupAddon, InputGroupText, Button,
    Container, Row, Col, Alert, Card, CardHeader, CardBody } from 'reactstrap';
import FormValidator from '../../../services/FormValidator';
import {getCcExpYears, BankAccountType} from '../../../services/PaymentFormHelper';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { CustomerProvider, RapidEnrollmentProvider } from '../../../providers';
import StatePicker from '../../Shared/StatePicker';
import { CustomerEnrollmentStatusEnum } from '../../../enums';
import { formatUsCurrency } from '../../../services/DataTableHelper';
    
const propTypes = {
    customer: PropTypes.object.isRequired,
    setupInvoice: PropTypes.object,
    isOpen: PropTypes.bool,
    isProcessing: PropTypes.bool,
    onPaymentInfoUpdated: PropTypes.func,
    toggle: PropTypes.func,
}

class PaymentInfoPanel extends PalladientComponent {
    constructor(props) {
        super(props);

        this.bankAccountValidator = new FormValidator([
            {
                field: 'bankName',
                method: 'isEmpty',
                validWhen: false,
                message: 'Bank name was not provided'
            },
            {
                field: 'routingNumber',
                method: 'isNumeric',
                validWhen: true,
                message: 'Routing number is not valid'
            },
            {
                field: 'accountNumber',
                method: 'isNumeric',
                validWhen: true,
                message: 'Account number is not valid'
            },
        ]);

        this.creditCardValidator = new FormValidator([
            {
                field: 'cardNumber',
                method: 'isEmpty',
                validWhen: false,
                message: 'Card number was not provided'
            },
            {
                field: 'billingFirstName',
                method: 'isEmpty',
                validWhen: false,
                message: 'First Name cannot be empty'
            },
            {
                field: 'billingLastName',
                method: 'isEmpty',
                validWhen: false,
                message: 'Last Name cannot be empty'
            },  
            {
              field: 'billingAddressLine1',
              method: 'isEmpty',
              validWhen: false,
              message: 'Address cannot be empty'
            },
            {
              field: 'billingCity',
              method: 'isEmpty',
              validWhen: false,
              message: 'Address must have a city'
            },
            {
              field: 'billingZip',
              method: 'isEmpty',
              validWhen: false,
              message: 'Please enter 5 digit zip code'
            },
          ]);

        this.defineState({
            paymentMethod: '',
            storedPaymentType: '',
            saving: false,
            nameOnAccount: '',            
            cardNumber: '',
            cardExpMonth: new Date().getMonth(),
            cardExpYear: new Date().getFullYear(),
            cardCode: '',
            routingNumber: '',
            accountNumber: '',
            bankName: '',
            typeOfAccount: '',
            billingFirstName: '',
            billingLastName: '',
            billingAddressLine1: '',
            billingAddressLine2: '',
            billingCity: '',
            billingState: '',
            billingZip: '',
            ccValidation: this.creditCardValidator.valid(),
            baValidation: this.bankAccountValidator.valid(),
        });

        this.paymentInfoSubmitted = false;
        this.customerProvider = new CustomerProvider();
        this.enrollmentProvider = new RapidEnrollmentProvider();
    }

    handleAddressStateChanged(stateData) {
        this.setState({billingState: stateData.abbreviation});
    }
  
    handlePaymentMethodChanged(e) {
        this.setState({storedPaymentType: e.target.value});
    }

    handleSavePaymentClicked() {
        var isValid = true;
        this.paymentInfoSubmitted = true;
        if (this.state.storedPaymentType === 'CreditCard') {
            var validation = this.creditCardValidator.validate(this.state);            
            isValid = validation.isValid;
            if (!isValid) this.setState({ccValidation: validation});
        } else if (this.state.storedPaymentType === 'BankAccount') {
            var validation = this.bankAccountValidator.validate(this.state);
            isValid = validation.isValid;
            if (!isValid) this.setState({baValidation: validation});
        }
        
        if (isValid) {
            var paymentProfile = {                
                addressLine1: this.state.billingAddressLine1,
                addressLine2: this.state.billingAddressLine2,
                city: this.state.billingCity,
                state: this.state.billingState,
                zip: this.state.billingZip,
                phone: this.state.billingPhone,
                cardNumber: this.state.cardNumber.replace(/\s+/g, ''),
                cardExpMonth: this.state.cardExpMonth,
                cardExpYear: this.state.cardExpYear,
                cardCode: this.state.cardCode,
                routingNumber: this.state.routingNumber,
                accountNumber: this.state.accountNumber,
                paymentType: this.state.storedPaymentType,
                typeOfAccount: this.state.typeOfAccount,
                nameOnAccount: this.state.nameOnAccount,
                bankName: this.state.bankName,          
                firstName: this.state.billingFirstName,  
                lastName: this.state.billingLastName,
            }
            paymentProfile.method = this.state.storedPaymentType === 'CreditCard' ? 'Credit' : 'Ach';
            if (this.props.onPaymentInfoUpdated) this.props.onPaymentInfoUpdated(paymentProfile);
        }
    }

    render() {
        let ccValidation = this.paymentInfoSubmitted ? this.creditCardValidator.validate(this.state) : this.state.ccValidation;
        let baValidation = this.paymentInfoSubmitted ? this.bankAccountValidator.validate(this.state) : this.state.baValidation;

        return (
            <>
                <Form>
                    <Row>
                        <Col className="text-right">
                            <h6>Amount Due Now: {formatUsCurrency(this.props.setupInvoice.billedAmount)}</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={4} className="border-right border-secondary">
                            <Label className="font-weight-bold">Select Payment Method</Label>
                            <FormGroup check className="radio">
                                <Input className="form-check-input" type="radio" id="storedPaymentType" name="storedPaymentType" value="CreditCard" checked={this.state.storedPaymentType === 'CreditCard'} onChange={this.handlePaymentMethodChanged.bind(this)}/>
                                <Label check className="form-check-label" htmlFor="storedPaymentType">Credit card</Label>
                            </FormGroup>
                            <FormGroup check className="radio">
                                <Input className="form-check-input" type="radio" id="storedPaymentType" name="storedPaymentType" value="BankAccount"  checked={this.state.storedPaymentType === 'BankAccount'} onChange={this.handlePaymentMethodChanged.bind(this)}/>
                                <Label check className="form-check-label" htmlFor="storedPaymentType">Bank Account</Label>
                            </FormGroup>
                        </Col>
                        <Col xs={12} lg={8}>
                            {
                                this.state.storedPaymentType === 'CreditCard' ? (
                                    <React.Fragment>
                                        <Label className="font-weight-bold">Enter credit card details</Label>
                                        <Row>
                                            <Col xs="12">
                                                <FormGroup>
                                                    <Label htmlFor="cardNumber">Card Number</Label>
                                                    <Input type="text" id="cardNumber" placeholder={this.props.customer.accountType === 'CreditCard' && this.props.customer.accountNumberLastFour ? '...' + this.props.customer.accountNumberLastFour : '0000 0000 0000 0000'} required value={this.state.cardNumber} onChange={this.handleChange} invalid={ccValidation.cardNumber.isInvalid}/>
                                                    <FormFeedback valid={false}>{ccValidation.cardNumber.message}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            </Row>
                                            <Row>
                                            <Col xs="4">
                                                <FormGroup>
                                                <Label htmlFor="cardExpMonth">Exp. Month</Label>
                                                <Input type="select" name="cardExpMonth" id="cardExpMonth" value={this.state.cardExpMonth} onChange={this.handleChange}>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col xs="4">
                                                <FormGroup>
                                                <Label htmlFor="cardExpYear">Exp. Year</Label>
                                                <Input type="select" name="cardExpYear" id="cardExpYear" value={this.state.cardExpYear} onChange={this.handleChange}>
                                                    {
                                                        getCcExpYears().map((year, index) =>(
                                                            <option key={'year_' + year} value={year}>{year}</option>
                                                        ))
                                                    }
                                                </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col xs="4">
                                                <FormGroup>
                                                    <Label htmlFor="cardCode">CVV/CVC</Label>
                                                    <Input type="text" id="cardCode" placeholder="123" required value={this.state.cardCode} onChange={this.handleChange}/>
                                                </FormGroup>
                                            </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12}>
                                                    <Label className="font-weight-bold">Name and Billing Address</Label>
                                                    <FormGroup>
                                                        <Row form className="mb-3">
                                                            <Col xs={6}>
                                                                <InputGroup controlid="billingFirstName">
                                                                    <Input type="text" id="billingFirstName" placeholder="First Name" value={this.state.billingFirstName} onChange={this.handleChange} invalid={ccValidation.billingFirstName.isInvalid}/>
                                                                    <FormFeedback valid={false}>{ccValidation.billingFirstName.message}</FormFeedback>
                                                                </InputGroup>
                                                            </Col>
                                                            <Col xs={6}>
                                                                <InputGroup controlid="billingLastName">
                                                                    <Input type="text" id="billingLastName" placeholder="Last Name" value={this.state.billingLastName} onChange={this.handleChange} invalid={ccValidation.billingLastName.isInvalid}/>
                                                                    <FormFeedback valid={false}>{ccValidation.billingLastName.message}</FormFeedback>
                                                                </InputGroup>
                                                            </Col>
                                                        </Row>
                                                        <InputGroup controlid="billingAddressLine1">
                                                            <Input type="text" id="billingAddressLine1" placeholder="Address Line 1" value={this.state.billingAddressLine1} onChange={this.handleChange} invalid={ccValidation.billingAddressLine1.isInvalid}/>
                                                            <FormFeedback valid={false}>{ccValidation.billingAddressLine1.message}</FormFeedback>
                                                        </InputGroup>
                                                        </FormGroup>
                                                        <FormGroup>
                                                        <InputGroup controlid="billingAddressLine2">
                                                            <Input type="text" id="billingAddressLine2" placeholder="Address Line 2" value={this.state.billingAddressLine2} onChange={this.handleChange}/>
                                                        </InputGroup>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                        <Col xs={4}>
                                                            <InputGroup controlid="billingCity">
                                                                <Input type="text" id="billingCity" placeholder="City" value={this.state.billingCity} onChange={this.handleChange} invalid={ccValidation.billingCity.isInvalid}/>
                                                                <FormFeedback valid={false}>{ccValidation.billingCity.message}</FormFeedback>
                                                            </InputGroup>
                                                        </Col>
                                                        <Col xs={4}>
                                                            <InputGroup controlid="billingState">
                                                                <StatePicker id="billingState" className="form-control" value={this.state.billingState} onChange={this.handleAddressStateChanged.bind(this)}/>
                                                            </InputGroup>
                                                        </Col>
                                                        <Col xs={4}>
                                                            <InputGroup controlid="billingZip">
                                                                <Input type="text" id="billingZip" placeholder="Zip" value={this.state.billingZip} onChange={this.handleChange} invalid={ccValidation.billingZip.isInvalid}/>
                                                                <FormFeedback valid={false}>{ccValidation.billingZip.message}</FormFeedback>
                                                            </InputGroup>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                    </React.Fragment>
                                ) : this.state.storedPaymentType === 'BankAccount' ? (
                                    <React.Fragment>
                                        <Label className="font-weight-bold">Connect a bank account</Label>
                                        <FormGroup>
                                            <Label htmlFor="bankName">Name of Bank</Label>
                                            <Input type="text" id="bankName" placeholder="Enter the name of your bank" required value={this.state.bankName} onChange={this.handleChange} invalid={baValidation.bankName.isInvalid}/>
                                            <FormFeedback valid={false}>{baValidation.bankName.message}</FormFeedback>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label htmlFor="typeOfAccount">Type of Account</Label>
                                            <Input type="select" id="typeOfAccount" value={this.state.typeOfAccount} onChange={this.handleChange}>
                                                <option value={BankAccountType.None}>-- Select an account type --</option>
                                                <option value={BankAccountType.Checking}>{BankAccountType.Checking}</option>
                                                <option value={BankAccountType.Savings}>{BankAccountType.Savings}</option>
                                            </Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label htmlFor="routingNumber">Routing Number</Label>
                                            <Input type="text" id="routingNumber" placeholder="000000000" required value={this.state.routingNumber} onChange={this.handleChange} invalid={baValidation.routingNumber.isInvalid}/>
                                            <FormFeedback valid={false}>{baValidation.routingNumber.message}</FormFeedback>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label htmlFor="accountNumber">Account Number</Label>
                                            <Input type="text" id="accountNumber" placeholder={this.props.customer.accountType === 'BankAccount' && this.props.customer.accountNumberLastFour ? '...' + this.props.customer.accountNumberLastFour : '000000000'} required value={this.state.accountNumber} onChange={this.handleChange} invalid={baValidation.accountNumber.isInvalid}/>
                                            <FormFeedback valid={false}>{baValidation.accountNumber.message}</FormFeedback>
                                        </FormGroup>
                                    </React.Fragment>
                                ) : ''
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr className="text-muted"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-right">
                            <Button color="primary" onClick={this.handleSavePaymentClicked.bind(this)} disabled={this.props.isProcessing}>{this.props.isProcessing ? 'Processing...' : 'Process Payment'}</Button>{' '}
                            <div className="text-muted text-right">
                                <small>The amount due shown will be debited immediately and your payment information will be stored with our payment partner for monthly payment processing.</small>
                            </div>
                        </Col>
                    </Row>
                </Form>                  
            </>
        );
    }
}

PaymentInfoPanel.propTypes = propTypes;

export default PaymentInfoPanel;