import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Collapse, Container, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Alert } from 'reactstrap';
import logo from '../../../assets/img/brand/logo.png'
import { NoteabillApi, PasswordRecoveryEndpoint } from '../../../services/NoteabillApi';
import FormValidator from '../../../services/FormValidator';
import 'spinkit/css/spinkit.css';
import queryString from 'query-string';
import passwordValidator from 'password-validator';

const RecoveryEndpoint = PasswordRecoveryEndpoint;

const logoStyle = {
  height: '50px',
  textAlign: 'center',
  marginBottom: '15px'
}

class ResetPasswordPage extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
          {
            field: 'password',
            method: 'isEmpty',
            validWhen: false,
            message: 'You must enter a new password'
          },
          {
            field: 'password',
            method: this.passwordStrength,
            validWhen: true,
            message: 'Please choose a stronger password'
          },
          {
            field: 'passwordConfirm',
            method: 'isEmpty',
            validWhen: false,
            message: 'You must re-type your password here'
          },
          {
            field: 'passwordConfirm',
            method: this.passwordMatch, // custom function
            validWhen: true,
            message: 'The passwords do not match'
          }
        ]);
    
        this.state = {
          alertVisible: false,
          alertMessage: "",
          alertColor: "info",
          loading: false,
          formVisible: false,
          formEnabled: true,
          buttonText: "Change Password",
          password: "",
          passwordConfirm: "",
          validation: this.validator.valid()
        }

        this.submitted = false;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
    }

    passwordMatch = (confirmation, state) => (state.password === confirmation);

    passwordStrength = (password) => {
      var schema = new passwordValidator();
      schema
        .is().min(8)
        .is().max(100)
        .has().uppercase()
        .has().lowercase()
        .has().digits()
        .has().not().spaces();
      return schema.validate(password);
    };

    handleChange = (event) => {
      this.setState({
        [event.target.id]: event.target.value
      });
    }

    handleSubmit = (event) => {
      event.preventDefault();

      // validate
      const validation = this.validator.validate(this.state);
      this.setState({validation})
      this.submitted = true;

      if (validation.isValid) {
        // prepare request to the auth endpoint
        this.disableForm();
        var api = new NoteabillApi();
        const qs = queryString.parse(this.props.location.search);
        var endpoint = RecoveryEndpoint + '/' + qs.requestId;
        api.post(endpoint, JSON.stringify({password: this.state.password}), false)
          .then(response => this.handleErrorResponse(response))
          .then(data => data && this.handleCompleted())
          .catch(error => this.handleRejected(error));
      }
    }

    handleCompleted() {  
      this.setState({ formVisible: false });
      this.showAlert("Success! Return to the sign-in page and use your new password to login", "success");
    }
  
    handleErrorResponse(response) {
      if (response.status === 404) {
        this.showAlert("The password recovery token was not found", "danger");
        this.setState({ loading: false });
      } 
      else if (response.status === 401) {
        this.showAlert("Password recovery requests expire after a short time. You'll need to submit another and respond right away.", "warning")
        this.setState({ loading: false });
      }
      else if (!response.ok) {
          this.showAlert("Hmm, something went wrong: " + response.statusText, "danger");
          this.setState({ loading: false });
        } else {
        return response;
      }
    }
  
    handleRejected(error) {
      console.log(error);
      this.showAlert("An unexpected error occurred: " + error, "danger");
      this.enableForm();
      this.setState({ loading: false });
    }
    
    showAlert(message, color) {
      this.setState({ alertVisible: true, alertMessage: message, alertColor: color });
    }
  
    hideAlert() {
      this.setState({ alertVisible: false });
    }
  
    disableForm() {
      this.setState({ formEnabled: false, alertVisible: false, alertText: "" });
    }
  
    enableForm() {
      this.setState({ formEnabled: true, buttonText: "Submit" });
    }

    componentDidMount() {
      this.setState({ loading: true });
      const qs = queryString.parse(this.props.location.search);
      if (!qs.requestId) {
        this.showAlert('You must be here by mistake. You have not provided a password recovery token.');
        this.setState({ loading: false });
      } else {
        var api = new NoteabillApi();
        var endpoint = RecoveryEndpoint + '/' + qs.requestId;
        api.get(endpoint, false)
          .then(response => this.handleErrorResponse(response))
          .then(response => response && response.json())
          .then(data => data && this.handleCheckRequestCompleted())
          .catch(error => this.handleRejected(error));  
      }
    }

    handleCheckRequestCompleted() {
      this.setState({ loading: false, formVisible: true });
    }
  
    render() {
      let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation;
      return (
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="5">
                <Alert color={this.state.alertColor} isOpen={this.state.alertVisible} toggle={this.hideAlert}>{this.state.alertMessage}</Alert>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="5">
                <Card className="p-4">
                  <CardBody>
                    <Container>
                      <Row className="justify-content-center">
                        <img src={logo} style={logoStyle} alt="Logo" />
                      </Row>
                    </Container>
                    <Collapse isOpen={this.state.loading}>
                      <Container>
                        <Row className="justify-content-center">
                          <div className="sk-wave">
                            <div className="sk-rect sk-rect1"></div>&nbsp;
                            <div className="sk-rect sk-rect2"></div>&nbsp;
                            <div className="sk-rect sk-rect3"></div>&nbsp;
                            <div className="sk-rect sk-rect4"></div>&nbsp;
                            <div className="sk-rect sk-rect5"></div>
                          </div>
                        </Row>
                        <Row className="justify-content-center">
                          <p>Checking status of password reset request...</p>
                        </Row>
                      </Container>
                    </Collapse>
                    <Collapse isOpen={this.state.formVisible}>
                      <Form onSubmit={this.handleSubmit} disabled={!this.state.formEnabled}>
                        <h1>Reset your password</h1>
                        <p className="text-muted">
                            Enter a new password for your account below. Your password should
                            consist of a minimum of 8 alphanumeric characters and exhibit a mixture
                            of upper and lower case and at least one digit.
                        </p>
                        <FormGroup>
                          <InputGroup controlid="password" className="mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="icon-lock"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input type="password" id="password" placeholder="New Password" value={this.state.password} onChange={this.handleChange} invalid={validation.password.isInvalid}/>
                              <FormFeedback valid={false}>{validation.password.message}</FormFeedback>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup controlid="passwordConfirm" className="mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="icon-lock"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input type="password" id="passwordConfirm" placeholder="Re-enter New Password" value={this.state.passwordConfirm} onChange={this.handleChange} invalid={validation.passwordConfirm.isInvalid}/>
                              <FormFeedback valid={false}>{validation.passwordConfirm.message}</FormFeedback>
                          </InputGroup>
                        </FormGroup>
                        <Row>
                          <Col xs="12">
                            <Button color="primary" block>{this.state.buttonText}</Button>
                          </Col>
                        </Row>
                      </Form>
                    </Collapse>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content-center">
                <Button color="link" className="px-0" href="/#/signin">Go to sign-in page</Button>
            </Row>
          </Container>
        </div>
      );
    }
}

export default ResetPasswordPage;