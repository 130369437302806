import Moment from 'react-moment';
import moment from 'moment';

export const selectRow = {
    mode: 'checkbox',
};

export const cellEdit = {
    mode: 'click',
}

export function usCurrencyFormatter(cell, row) {
    return formatUsCurrency(cell);
}

export function stringListFormatter(cell, row) {
    if (cell.length < 1) return 'None';
    return cell.join(', ');
}

export function shortDateFormatter(cell, row) {
    if (!cell) return '';
    var formatted = moment(cell).format('MM/DD/YYYY');
    if (formatted === '01/01/0001') return '';
    else return formatted;
}

export function percentageFormatter(cell, row) {
    return (cell * 100) + '%';
}

export function formatUsCurrency(num)
{
    if (!num) return "$0.00";
    num = num.toString().replace(/\$|\,/g, '');
    if (isNaN(num))
    {
        num = "0";
    }

    if (num < 0.01) return "$" + num;

    var sign = (num == (num = Math.abs(num)));
    num = Math.floor(num * 100 + 0.50000000001);
    var cents = num % 100;
    num = Math.floor(num / 100).toString();

    if (cents < 10)
    {
        cents = "0" + cents;
    }
    for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
    {
        num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3));
    }

    return (((sign) ? '' : '-') + '$' + num + '.' + cents);
}