import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PalladientComponent from '../../../PalladientComponent';
import SubscriptionAgreementCard from '../../Shared/SubscriptionAgreementCard';

const propTypes = {
    agreementData: PropTypes.object,
    onAgreementAccepted: PropTypes.func,
}

class SubscriptionAgreementPanel extends PalladientComponent {
    constructor(props) {
        super(props);
    }

    handleAgreementAccepted() {
        if (this.props.onAgreementAccepted) this.props.onAgreementAccepted();
    }

    render() {
        if (!this.props.agreementData) return;
        return (
            <SubscriptionAgreementCard
            customer={this.props.agreementData.customer}
            contract={this.props.agreementData.contract}
            agreementTerms={this.props.agreementData.agreementTerms}
            primarySubscription={this.props.agreementData.primarySubscription}
            addonSubscriptions={this.props.agreementData.addonSubscriptions}
            products={this.props.agreementData.products}
            invoiceLocation={this.props.agreementData.invoiceLocation}
            onAccepted={this.handleAgreementAccepted.bind(this)}
            inReadMode={false}
            />
        )
    }
}

SubscriptionAgreementPanel.propTypes = propTypes;

export default SubscriptionAgreementPanel;