import React, { Component } from 'react';
import { Button, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';

class Page404 extends Component {
  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <div className="clearfix">
                <h1 className="float-left display-3 mr-4">404</h1>
                <h4 className="pt-3">Can't find that.</h4>
                <p className="text-muted float-left">
                  The page or resource you are looking for was not found.
                </p>
              </div>
              <hr className="text-muted"></hr>
              <div className="clearfix">
                <p className="text-muted">
                  You were redirected here because you attempted to access a resource that we couldn't find or that you don't
                  have access to.
                  It's possible you clicked an external link to an entity or workflow or other item that was either
                  deleted or renamed. Otherwise, there may be an issue and you should let us know by emailing <a href="mailto://support@noteablenow.com">support@noteablenow.com</a>.
                </p>
              </div>
              <Button color="primary" className="px-4" href="/">Go Home</Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Page404;
