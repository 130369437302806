import React, { Component } from 'react';
import { Container, Row, UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import { geoCentroid } from "d3-geo";
import { scaleQuantize } from "d3-scale";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation
} from "react-simple-maps";
import { UsStates } from '../../services/UsStates';
import allStates from "./data/allstates.json";

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const offsets = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21]
};

const colorScale = scaleQuantize()
  .domain([0, 9])
  .range([
    "#ffedea",
    "#ffcec5",
    "#ffad9f",
    "#ff8a75",
    "#ff5533",
    "#e2492d",
    "#be3d26",
    "#9a311f",
    "#782618"
  ]);

const propTypes = {
    data: PropTypes.array.isRequired,
}

class UsHeatMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            ready: false,
        }
    }

    componentDidMount() {
        const data = allStates.map(s => {
            const interim = this.props.data.find(i => i.code === s.id);
            return {
                id: s.val,
                code: s.id,
                count: interim ? interim.count : 0,
            }
        });
        this.setState({data: data, ready: true});
    }

    stateNameFromCode(code) {
      return UsStates.find(s => s.abbreviation === code).name;
    }

    renderLoading(loadingMessage) {
        return (
            <Container>
                <Row className="justify-content-center">
                    <div className="sk-wave">
                    <div className="sk-rect sk-rect1"></div>&nbsp;
                    <div className="sk-rect sk-rect2"></div>&nbsp;
                    <div className="sk-rect sk-rect3"></div>&nbsp;
                    <div className="sk-rect sk-rect4"></div>&nbsp;
                    <div className="sk-rect sk-rect5"></div>
                    </div>
                </Row>
                <Row className="justify-content-center">
                    <p>{loadingMessage ? loadingMessage : this.state.loadingMessage}</p>
                </Row>
            </Container>      
        );
    }

    render() {
        return this.state.ready ? (
            <ComposableMap projection="geoAlbersUsa">
              <Geographies geography={geoUrl}>
                {({ geographies }) => (
                  <>
                    {geographies.map(geo => {
                        const cur = this.state.data.find(s => s.id === geo.id);
                        return (
                          <React.Fragment key={geo.rsmKey}>
                            <Geography
                              id={'geography_' + cur.code}
                              stroke="#FFF"
                              geography={geo}
                              fill={colorScale(cur ? cur.count : "#DDD")}
                            />
                            <UncontrolledTooltip placement="bottom" target={'geography_' + cur.code}>
                                {this.stateNameFromCode(cur.code)} - {cur.count}
                            </UncontrolledTooltip>                                            
                          </React.Fragment>
                          )
                    })}
                    {geographies.map(geo => {
                      const centroid = geoCentroid(geo);
                      const cur = allStates.find(s => s.val === geo.id);
                      return (
                        <g key={geo.rsmKey + "-name"}>
                          {cur &&
                            centroid[0] > -160 &&
                            centroid[0] < -67 &&
                            (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                              <Marker coordinates={centroid}>
                                <text y="2" fontSize={14} textAnchor="middle">
                                  {cur.id}
                                </text>
                              </Marker>
                            ) : (
                              <Annotation
                                subject={centroid}
                                dx={offsets[cur.id][0]}
                                dy={offsets[cur.id][1]}
                              >
                                <text x={4} fontSize={14} alignmentBaseline="middle">
                                  {cur.id}
                                </text>
                              </Annotation>
                            ))}
                        </g>
                      );
                    })}
                  </>
                )}
              </Geographies>
            </ComposableMap>
          ) : this.renderLoading();        
    }
};

export default UsHeatMap;
