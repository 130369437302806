const CustomerEnrollmentStatusEnum = {
    Pending: 'Pending',
    Trial: 'Trial',
    Sent: 'Sent',
    Committed: 'Committed',
    Signed: 'Signed',
    PaymentConfirmed: 'PaymentConfirmed',
    Onboarding: 'Onboarding',
    Active: 'Active',
    Unenrolled: 'Unenrolled',
}

export default CustomerEnrollmentStatusEnum;