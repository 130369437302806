import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/salessummary';

class SalesSummaryProvider extends ProviderBase {    
    constructor() {
        super(BaseEndpoint);
    }

    getSummary() {
        return this.api.get(BaseEndpoint);
    }
}

export default SalesSummaryProvider;