import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Row, Alert } from 'reactstrap';
import logo from '../../../assets/img/brand/logo.png'
import { NoteabillApi, AuthorizationEndpoint } from '../../../services/NoteabillApi';
import cookie from 'react-cookies';

const AuthEndpoint = AuthorizationEndpoint;


const logoStyle = {
    height: '50px',
    textAlign: 'center',
    marginBottom: '15px'
  }

  class SignoutPage extends Component {
    constructor(props) {
     super(props);   

     this.state = {
        alertVisible: false,
        alertMessage: "",
        alertColor: "info",
        headingText: "Signing you out...",
        buttonText: "Please wait",
        buttonEnabled: false
     }
    }

    showAlert(message, color) {
        this.setState({ alertVisible: true, alertMessage: message, alertColor: color });
    }

    hideAlert() {
        this.setState({ alertVisible: false });
    }

    componentDidMount() {
        var api = new NoteabillApi();
        var token = cookie.load('token');
        if (token && token != 'undefined') {
            api.delete(AuthEndpoint, false)
            .then(response => this.handleError(response))
            .then(response => response && this.handleResponse(response))
            .catch(error => this.handleReject(error));    
        } else {
            this.setState({buttonEnabled: true, buttonText: "Sign in again", headingText: "You have been signed out."});
        }
    }

    handleError(response) {
        if (!response.ok) {
            this.showAlert("We were unable to sign you out. " + response.statusText);
        } else {
            return response;
        }
    }

    handleResponse(response) {
        cookie.remove('token');
        sessionStorage.removeItem('session');
        this.setState({buttonEnabled: true, buttonText: "Sign in again", headingText: "You have been signed out."});
    }

    handleReject(error) {
        this.showAlert("An error occurred while trying to sign you out. " + error);
    }
   
    render() {
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="9">
                            <Alert color={this.state.alertColor} isOpen={this.state.alertVisible} toggle={this.hideAlert}>{this.state.alertMessage}</Alert>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md="9">
                            <Card className="p-4">
                                <CardBody>
                                    <Container>
                                        <Row className="justify-content-center">
                                            <img src={logo} style={logoStyle} alt="Logo" />
                                        </Row>
                                        <Row className="justify-content-center">
                                            <h2>{this.state.headingText}</h2>
                                        </Row>
                                        <Row className="justify-content-center">
                                            <Button color="primary" className="px-4" href="#/" disabled={!this.state.buttonEnabled}>{this.state.buttonText}</Button>
                                        </Row>
                                    </Container>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
  }

  export default SignoutPage;
  