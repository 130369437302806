import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/incident',
        ForCustomerEndpoint = '/incident/customer/{0}';

class IncidentProvider extends ProviderBase {    
    constructor() {
        super(BaseEndpoint);
    }

    getForCustomer(customerId) {
        return this.api.get(ForCustomerEndpoint.format(customerId));
    }
}

export default IncidentProvider;