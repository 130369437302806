import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/product',
        ImageEndpoint = '/product/{0}/image',
        AgreementEndpoint = '/product/{0}/agreement/{1}';

class ProductProvider extends ProviderBase {    
    constructor() {
        super(BaseEndpoint);
    }

    getOne(id, includes) {
        return this._getOne(id, includes);
    }

    getAll(filter) {
        return this._getAll(filter);
    }

    post(data) {
        return this._post(data);
    }

    put(id, data) {
        return this._put(id, data);
    }

    patch(id, data) {
        return this._patch(id, data);
    }

    delete(id) {
        return this._delete(id);
    }

    postImage(id, imageData) {
        return this.api.post(ImageEndpoint.format(id), JSON.stringify({productImage: imageData}));
    }

    getAgreement(id, customerId) {
        return this.api.get(AgreementEndpoint.format(id, customerId));
    }
}

export default ProductProvider;