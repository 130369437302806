import React, { Component } from 'react';
import { 
      Button, Card, CardBody, Col, Collapse, Container, Form, FormFeedback, FormGroup, FormText,
      Input, Label, InputGroup, InputGroupAddon, InputGroupText, Row, Alert } from 'reactstrap';
import logo from '../../../assets/img/brand/logo.png'
import { CustomerEnrollmentProvider } from '../../../providers';
import FormValidator from '../../../services/FormValidator';
import 'spinkit/css/spinkit.css';
import queryString from 'query-string';
import passwordValidator from 'password-validator';
import PalladientComponent from '../../../PalladientComponent';
import StatePicker from '../../Shared/StatePicker';
import { TextMask, InputAdapter } from 'react-text-mask-hoc';

const logoStyle = {
    height: '50px',
    textAlign: 'center',
    marginBottom: '15px'
}
  
class CustomerEnrollmentPage extends PalladientComponent {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
          {
            field: 'companyName',
            method: 'isEmpty',
            validWhen: false,
            message: 'You must enter a company name'
          },
          {
            field: 'firstName',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please enter your first name'
          },
          {
            field: 'lastName',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please enter your last name'
          },
          {
            field: 'title',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please enter your title'
          },
          {
            field: 'addressLine1',
            method: 'isEmpty',
            validWhen: false,
            message: 'Address cannot be empty'
          },
          {
            field: 'city',
            method: 'isEmpty',
            validWhen: false,
            message: 'Address must have a city'
          },
          {
            field: 'zip',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please enter 5 digit zip code'
          },
          {
            field: 'password',
            method: this.passwordStrength,
            validWhen: true,
            message: 'Please choose a stronger password'
          },
          {
            field: 'passwordConfirm',
            method: 'isEmpty',
            validWhen: false,
            message: 'You must re-type your password here'
          },
          {
            field: 'passwordConfirm',
            method: this.passwordMatch, // custom function
            validWhen: true,
            message: 'The passwords do not match'
          }
        ]);
    
        this.defineState({
          formVisible: false,
          formEnabled: true,
          buttonText: "Continue to Dashboard",
          firstName: "",
          lastName: "",
          companyName: "",
          title: "",
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
          password: "",
          passwordConfirm: "",
          completed: false,
          validation: this.validator.valid(),
        });

        this.submitted = false;
        this.customerEnrollmentProvider = new CustomerEnrollmentProvider();

        this.handleAddressStateChanged = this.handleAddressStateChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
    }

    passwordMatch = (confirmation, state) => (state.password === confirmation);

    passwordStrength = (password) => {
      var schema = new passwordValidator();
      schema
        .is().min(8)
        .is().max(100)
        .has().uppercase()
        .has().lowercase()
        .has().digits()
        .has().not().spaces();
      return schema.validate(password);
    };

    handleChange = (event) => {
      this.setState({
        [event.target.id]: event.target.value
      });
    }

    handleSubmit(e) {
      // validate
      var validation = this.validator.validate(this.state);
      this.submitted = true;
      if (validation.isValid) {
        this.disableForm();
        const qs = queryString.parse(this.props.location.search);
        var enrollment = {
          password: this.state.password,
          firstName: this.state.firstName,
          lastName: this.state.lastName,                
          companyName: this.state.companyName,
          title: this.state.title,
          addressLine1: this.state.addressLine1,
          addressLine2: this.state.addressLine2,
          city: this.state.city,
          state: this.state.state,
          zip: this.state.zip,
          phone: this.state.phone,
        }
        this.customerEnrollmentProvider.patch(qs.invitationId, enrollment)
        .then(response => this.handleErrorResponse(response))
        .then(data => data && this.handleCompleted())
        .catch(error => this.handleRejected(error));
      }
      else {
        this.setState({validation:validation});
      }      
    }

    handleCompleted() {  
      this.setState({ formVisible: false, completed: true });
      this.showAlert("Success! Return to the sign-in page to login", "success");
    }
  
    handleErrorResponse(response) {
      if (response.status === 404) {
        this.showAlert("The confirmation token was not found", "danger");
        this.setState({ loading: false });
      } 
      else if (response.status === 401) {
        this.showAlert(response.statusText, "warning")
        this.setState({ loading: false });
      }
      else if (!response.ok) {
          this.showAlert("Hmm, something went wrong: " + response.statusText, "danger");
          this.enableForm();
          this.setState({ loading: false });          
        } else {
        return response;
      }
    }
  
    handleRejected(error) {
      this.showAlert("An unexpected error occurred: " + error, "danger");
      this.enableForm();
      this.setState({ loading: false });
    }
  
    disableForm() {
      this.setState({ formEnabled: false, alertVisible: false, alertText: "", buttonText: "Please wait..." });
    }
  
    enableForm() {
      this.setState({ formEnabled: true, buttonText: "Continue to Dashboard"});
    }

    componentDidMount() {
      this.setState({ loading: true });
      const qs = queryString.parse(this.props.location.search);
      if (!qs.invitationId) {
        this.showAlert('You must be here by mistake. You have not provided a confirmation token.');
        this.setState({ loading: false });
      } else {
        this.customerEnrollmentProvider.getOne(qs.invitationId)
          .then(response => this.handleErrorResponse(response))
          .then(response => response && response.json())
          .then(data => data && this.handleCheckRequestCompleted(data))
          .catch(error => this.handleRejected(error));  
      }
    }

    handleCheckRequestCompleted(data) {
      this.setState({ 
        firstName: data.firstName,
        lastName: data.lastName,
        companyName: data.companyName,
        loading: false, 
        formVisible: true 
      });
    }

    handleAddressStateChanged(stateData) {
      this.setState({state: stateData.abbreviation});
    }
  
    render() {
      let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation;
      return (
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="8">
                <Alert color={this.state.alertColor} isOpen={this.state.alertVisible} toggle={this.hideAlert}>{this.state.alertMessage}</Alert>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="8">
                <Card className="p-4">
                  <CardBody>
                    <Container>
                      <Row className="justify-content-center">
                        <img src={logo} style={logoStyle} alt="Logo" />
                      </Row>
                      <Row className="justify-content-center">
                        <h2>Account Management and Billing Center</h2>
                      </Row>
                      <Row>
                        <Col>
                          <hr className="text-dark"/>
                        </Col>
                      </Row>
                    </Container>
                    <Collapse isOpen={this.state.loading}>
                      <Container>
                        <Row className="justify-content-center">
                          <div className="sk-wave">
                            <div className="sk-rect sk-rect1"></div>&nbsp;
                            <div className="sk-rect sk-rect2"></div>&nbsp;
                            <div className="sk-rect sk-rect3"></div>&nbsp;
                            <div className="sk-rect sk-rect4"></div>&nbsp;
                            <div className="sk-rect sk-rect5"></div>
                          </div>
                        </Row>
                        <Row className="justify-content-center">
                          <p>Checking status of enrollment invitation...</p>
                        </Row>
                      </Container>
                    </Collapse>
                    <Collapse isOpen={this.state.formVisible}>
                      <Form disabled={!this.state.formEnabled}>
                        <p className="text-muted">
                            Thank you for confirming your email address. We need a bit more information
                            about your company/agency in order to generate your agreement and set
                            you up in our billing platform.
                        </p>
                        <FormGroup>
                          <Label>Company/Agency Name</Label>
                          <InputGroup controlid="companyName">
                            <Input type="text" id="companyName" placeholder="Enter Company Name" value={this.state.companyName} onChange={this.handleChange} invalid={validation.companyName.isInvalid}/>
                            <FormFeedback valid={false}>{validation.companyName.message}</FormFeedback>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup row>                                        
                            <Col xs={6}>
                                <Label>First Name</Label>
                                <InputGroup controlid="firstName">
                                    <Input type="text" id="firstName" placeholder="First" value={this.state.firstName} onChange={this.handleChange} invalid={validation.firstName.isInvalid}/>
                                    <FormFeedback valid={false}>{validation.firstName.message}</FormFeedback>
                                </InputGroup>
                            </Col>
                            <Col xs={6}>
                                <Label>Last Name</Label>
                                <InputGroup controlid="lastName">
                                    <Input type="text" id="lastName" placeholder="Last" value={this.state.lastName} onChange={this.handleChange} invalid={validation.lastName.isInvalid}/>
                                    <FormFeedback valid={false}>{validation.lastName.message}</FormFeedback>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                          <Label>Title</Label>
                          <InputGroup controlid="title">
                            <Input type="text" id="title" placeholder="Enter your title" value={this.state.title} onChange={this.handleChange} invalid={validation.title.isInvalid}/>
                            <FormFeedback valid={false}>{validation.title.message}</FormFeedback>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label>Phone</Label>
                          <InputGroup controlid="phone">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText><i className="fa fa-phone"></i></InputGroupText>
                            </InputGroupAddon>
                            <TextMask
                              id="phone"
                              name="phone"
                              mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                              Component={InputAdapter}
                              className="form-control"
                              onChange={this.handleChange}
                            />
                          </InputGroup>
                          <FormText color="muted">
                            ex. (999) 999-9999
                          </FormText>
                        </FormGroup>
                        <FormGroup>
                          <Label>Address</Label>
                          <InputGroup controlid="addressLine1">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-location-pin"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input type="text" id="addressLine1" placeholder="Address Line 1" value={this.state.addressLine1} onChange={this.handleChange} invalid={validation.addressLine1.isInvalid}/>
                            <FormFeedback valid={false}>{validation.addressLine1.message}</FormFeedback>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup controlid="addressLine2">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-location-pin"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input type="text" id="addressLine2" placeholder="Address Line 2" value={this.state.addressLine2} onChange={this.handleChange}/>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup row>
                          <Col xs={4}>
                            <InputGroup controlid="city">
                                <Input type="text" id="city" placeholder="City" value={this.state.city} onChange={this.handleChange} invalid={validation.city.isInvalid}/>
                                <FormFeedback valid={false}>{validation.city.message}</FormFeedback>
                            </InputGroup>
                          </Col>
                          <Col xs={4}>
                            <InputGroup controlid="state">
                              <StatePicker id="state" className="form-control" onChange={this.handleAddressStateChanged}/>
                            </InputGroup>
                          </Col>
                          <Col xs={4}>
                            <InputGroup controlid="zip">
                                <Input type="text" id="zip" placeholder="Zip" value={this.state.zip} onChange={this.handleChange} invalid={validation.zip.isInvalid}/>
                                <FormFeedback valid={false}>{validation.zip.message}</FormFeedback>
                            </InputGroup>
                          </Col>
                        </FormGroup>
                        <p className="text-muted">
                            In order to protect your privacy, we insist on the use of
                            a strong password. Therefore, make sure the password you choose is at least
                            8 alpha-numeric characters long, contains a mixture of upper and lower case
                            and at least one number.
                        </p>
                        <FormGroup>
                          <InputGroup controlid="password" className="mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="icon-lock"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input type="password" id="password" placeholder="New Password" value={this.state.password} onChange={this.handleChange} invalid={validation.password.isInvalid}/>
                              <FormFeedback valid={false}>{validation.password.message}</FormFeedback>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup controlid="passwordConfirm" className="mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="icon-lock"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input type="password" id="passwordConfirm" placeholder="Re-enter New Password" value={this.state.passwordConfirm} onChange={this.handleChange} invalid={validation.passwordConfirm.isInvalid}/>
                              <FormFeedback valid={false}>{validation.passwordConfirm.message}</FormFeedback>
                          </InputGroup>
                        </FormGroup>
                        <Row>
                          <Col xs="12">
                            <Button color="primary" block onClick={this.handleSubmit}>{this.state.buttonText}</Button>
                          </Col>
                        </Row>
                      </Form>
                    </Collapse>
                    <Collapse isOpen={this.state.completed}>
                        <Container>
                            <Row>
                                <h1>Let's Get Started!</h1>
                                <p className="text=muted">
                                  Thanks for enrolling in the Noteable Account Management and Billing Center. Next lets get you signed in
                                  so you can review your subscription agreement and enter your recurring payment info. Click the link below
                                  to go to the sign-in page. <strong>We can't start setting up your account until you've accepted the agreement
                                  and paid the setup invoice.</strong>
                                </p>
                            </Row>
                        </Container>
                    </Collapse>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content-center">
                <Button color="link" className="px-0" href="/#/signin">Go to sign-in page</Button>
            </Row>
          </Container>
        </div>
      );
    }
}

export default CustomerEnrollmentPage;