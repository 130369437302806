const Settings = {
    BaseUrl: process.env.REACT_APP_URL,
    ApiBaseUrl: process.env.REACT_APP_API_URL + "/api",
    ApiKey: process.env.REACT_APP_API_KEY,
    DefaultRoute: '/',
    FirebaseDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    CompanyName: 'Noteable',  
    DemoTenantId: '00000024',
    CompassTenantId: '00000006',   
    SuperAdminId: process.env.REACT_APP_SUPERADMIN_ID,
}

export default class Config {
    static getSetting(key) {
        return Settings[key];
    }

    static get BaseUrl() {
        return Config.getSetting('BaseUrl');
    }

    static get ApiBaseUrl() {
        return Config.getSetting('ApiBaseUrl');
    }

    static get ApiKey() {
        return Config.getSetting('ApiKey');
    }

    static get DefaultRoute() {
        return Config.getSetting('DefaultRoute');
    }

    static get FirebaseDomain() {
        return Config.getSetting('FirebaseDomain');
    }

    static get CompanyName() {
        return Config.getSetting('CompanyName');
    }

    static get DemoTenantId() {
        return Config.getSetting('DemoTenantId');
    }

    static get CompassTenantId() {
        return Config.getSetting('CompassTenantId');
    }

    static get SuperAdminId() {
        return Config.getSetting('SuperAdminId');
    }
}