import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/trainingvideo',
        ContentEndpoint = '/trainingvideo/{0}/content',
        StreamingTokenEndpoint = '/trainingvideo/{0}/token',
        StreamingEndpoint = '/trainingvideo/{0}/stream?streamingToken={1}';

class TrainingVideoProvider extends ProviderBase {    
    constructor() {
        super(BaseEndpoint);
    }

    getOne(id, includes) {
        return this._getOne(id, includes);
    }

    delete(id) {
        return this._delete(id);
    }

    uploadContent(id, fileData) {
        return this.api.postFile(ContentEndpoint.format(id), fileData);
    }

    downloadContent(id) {
        return this.api.get(ContentEndpoint.format(id));
    }
    
    getStreamingToken(id) {
        return this.api.get(StreamingTokenEndpoint.format(id));
    }

    getVideoStreamUrl(id, streamingToken) {
        return this.api.apiBaseUrl + StreamingEndpoint.format(id, streamingToken);
    }
}

export default TrainingVideoProvider;