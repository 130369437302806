import Config from '../config';
import cookie from 'react-cookies';

class Api {
    constructor() {
        this.apiBaseUrl = Config.ApiBaseUrl;
        this.apiKey = Config.ApiKey;
    }

    doFetch(path, method, headers, body) {        
        var startTimestamp = new Date().getTime(); 
        var url = this.apiBaseUrl + path;
        var token = cookie.load('token');
        if (token) headers.append('Authorization', 'token ' + token);
        if (this.apiKey) headers.append('ApiKey', this.apiKey);
        console.log("calling server: " + method + " - " + url);
        var fetchPromise = headers ? 
            fetch(url, {method: method, mode:'cors', headers: headers, body: body}) :
            fetch(url, {method: method, mode:'cors', body: body});
        return fetchPromise
            .then(function(response) {
                var endTimestamp = new Date().getTime();
                var timespan = endTimestamp - startTimestamp;
                console.log("(" + timespan + "ms) " + "response for " + method + " - " + url + ": " + response.status + " - " + response.statusText);
                return response;
            });
    }

    post(path, body) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return this.doFetch(path, 'post', headers, body);
    }

    postImage(path, image, imageType) {
        var headers = new Headers();
        headers.append('Content-Type', 'image/' + imageType);
        return this.doFetch(path, 'post', headers, image);
    }

    postFile(path, file) {
        var headers = new Headers();
        var formData = new FormData();        
        formData.append('file', file);
        return this.doFetch(path, 'post', headers, formData);
    }

    put(path, body) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return this.doFetch(path, 'put', headers, body);
    }

    patch(path, body) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return this.doFetch(path, 'patch', headers, body);
    }

    get(path) {
        return this.doFetch(path, 'get', new Headers(), null);
    }

    delete(path) {
        return this.doFetch(path, 'delete', new Headers(), null);
    }
}

export default Api;