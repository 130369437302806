import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Alert } from 'reactstrap';
import logo from '../../../assets/img/brand/logo.png'
import { NoteabillApi } from '../../../services/NoteabillApi';
import FormValidator from '../../../services/FormValidator';


const RecoveryEndpoint = "/authorization/recovery";

const logoStyle = {
  height: '50px',
  textAlign: 'center',
  marginBottom: '15px'
}

class ForgotPasswordPage extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
          {
            field: 'email',
            method: 'isEmpty',
            validWhen: false,
            message: 'Email is required'
          },
          {
            field: 'email',
            method: 'isEmail',
            validWhen: true,
            message: 'Please enter a valid email'
          }    
        ]);
    
        this.state = {
          email: "",
          alertVisible: false,
          alertMessage: "",
          alertColor: "info",
          formEnabled: true,
          buttonText: "Send Email",
          validation: this.validator.valid()
        }

        this.submitted = false;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
    
    }

    handleChange = (event) => {
      this.setState({
        [event.target.id]: event.target.value
      });
    }

    handleSubmit = (event) => {
      event.preventDefault();

      // validate
      const validation = this.validator.validate(this.state);
      this.setState({validation})
      this.submitted = true;

      if (validation.isValid) {
        // prepare request to the auth endpoint
        this.disableForm();
        var api = new NoteabillApi();
        api.post(RecoveryEndpoint, JSON.stringify({email: this.state.email}), false)
          .then(response => this.handleUnauthorized(response))
          .then(data => data && this.handleCompleted())
          .catch(error => this.handleRejected(error));
      }
    }

    handleCompleted() {  
      this.showAlert("An email has been sent to the address provided. Please check your email.");
      this.enableForm();
    }
  
    handleUnauthorized(response) {
      if (!response.ok) {
        if (response.status == 401) {
          this.showAlert("That email address could not be found.", "danger");
        } else {
          this.showAlert("Hmm, something went wrong " + response.statusText, "danger");
        }
        this.enableForm();
      } else {
        return response;
      }
    }
  
    handleRejected(error) {
      console.log(error);
      this.showAlert("An unexpected error occurred: " + error, "danger");
      this.enableForm();
    }
    
    showAlert(message, color) {
      this.setState({ alertVisible: true, alertMessage: message, alertColor: color });
    }
  
    hideAlert() {
      this.setState({ alertVisible: false });
    }
  
    disableForm() {
      this.setState({ formEnabled: false, buttonText: "Sending...", alertVisible: false, alertText: "" });
    }
  
    enableForm() {
      this.setState({ formEnabled: true, buttonText: "Submit" });
    }
  
    render() {
      let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation;
      return (
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="5">
                <Alert color={this.state.alertColor} isOpen={this.state.alertVisible} toggle={this.hideAlert}>{this.state.alertMessage}</Alert>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="5">
                <Card className="p-4">
                  <CardBody>
                    <Container>
                      <Row className="justify-content-center">
                        <img src={logo} style={logoStyle} alt="Logo" />
                      </Row>
                    </Container>
                    <Form onSubmit={this.handleSubmit} disabled={!this.state.formEnabled}>
                      <h1>Recover a password</h1>
                      <p className="text-muted">
                          Please enter the email address with which you sign in. If we recognize it, we'll
                          send an email with instructions to recover your password.
                      </p>
                      <FormGroup>
                        <InputGroup controlid="email" className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-envelope"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input type="email" id="email" autoFocus placeholder="Email" autoComplete="email" value={this.state.email} onChange={this.handleChange} invalid={validation.email.isInvalid}/>
                            <Button className="ml-1" color="primary">{this.state.buttonText}</Button>
                            <FormFeedback valid={false}>{validation.email.message}</FormFeedback>
                        </InputGroup>
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content-center">
                Still stuck? Email <a className="px-1" href="mailto:support@palladient.com">support@noteablenow.com</a> for assistance.
            </Row>
            <Row className="justify-content-center">
                <Button color="link" className="px-0" href="/#/signin">Back to sign-in page</Button>
            </Row>
          </Container>
        </div>
      );
    }
}

export default ForgotPasswordPage;