import React, { Component } from 'react';
import { Badge, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import '../../services/StringExtensions';
import { NoteabillApi, TasksForUserEndpoint } from '../../services/NoteabillApi';
import TimeAgo from 'react-timeago';

const defaultProps = {
  notif: false,
  accnt: false,
  tasks: false,
  mssgs: false,
};

class DefaultHeaderDropdown extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      tasks: [],
      avatar: null,
      userEmailAddress: '',
      userDisplayName: '',
      customerName: '',
    };

    this.timer = null;

    this.toggle = this.toggle.bind(this);
    this.refreshAvatar = this.refreshAvatar.bind(this);
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  componentDidMount() {
    const { accnt, tasks } = this.props;
    var sessionString = sessionStorage.getItem('session');
    if (sessionString && sessionString !== 'undefined') {
      var session = JSON.parse(sessionString);
      if (accnt && session) {
        this.setState({avatar: session.avatarImage, userEmailAddress: session.emailAddress, customerName: session.companyName, userDisplayName: session.firstName + ' ' + session.lastName});
        window.addEventListener('session', this.refreshAvatar);
      }
    }
  }

  componentWillUnmount() {
    const { accnt } = this.props;
    if (accnt) {
      window.removeEventListener('session', this.refreshAvatar);
    }
  }

  refreshAvatar() {
    var sessionString = sessionStorage.getItem('session');
    if (sessionString && sessionString !== 'undefined') {
      var session = JSON.parse(sessionString);
      this.setState({avatar: session.avatarImage, userEmailAddress: session.emailAddress});  
    }
  }
    
  userIsAdmin() {
    var session = JSON.parse(sessionStorage.getItem('session'));
    return session && session.securityZoneId.isDefaultGuid();
  }

  dropAccnt() {
    return (
      <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle nav>
          {
            this.state.avatar ? (
              <img src={this.state.avatar} className="img-avatar" alt={this.state.userEmailAddress} />
            ) : (
              <span className="text-dark">{this.state.userDisplayName ? this.state.userDisplayName : this.state.userEmailAddress} ({this.state.customerName})&nbsp;<i className="fa fa-caret-down"/></span>
            )
          }
        </DropdownToggle>
        <DropdownMenu className="shadow-sm">
          <DropdownItem href="/#/profile"><i className="fa fa-user" /> Profile</DropdownItem>
          <DropdownItem href="/#/signout"><i className="fa fa-lock" /> Sign out</DropdownItem>
          {
            this.userIsAdmin() ? (
              <DropdownItem href="/#/admin/settings"><i className="fa fa-cog" />Company Settings</DropdownItem>
            ) : ''
          }
        </DropdownMenu>
      </Dropdown>
    );
  }

  render() {
    return (
      this.dropAccnt()
    );
  }
}

export default DefaultHeaderDropdown;
