import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Row, Col, Table, Button,
         Input, Collapse, Alert } from 'reactstrap';
import PalladientComponent, { toastStyle } from '../../../PalladientComponent';
import { InvoiceProvider } from '../../../providers';
import moment from 'moment';
import { formatUsCurrency } from '../../../services/DataTableHelper';
import { InvoiceLineItemTypeEnum } from '../../../enums';
import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import TimeAgo from 'react-timeago';

class ViewInvoicePage extends PalladientComponent {
    constructor(props) {
        super(props);
        
        this.defineState({
            invoice: null,
            payNowModalIsOpen: false,
            historyPanelIsExpanded: false,
        });

        this.invoiceProvider = new InvoiceProvider();

        this.handlePrintClicked = this.handlePrintClicked.bind(this);
        this.handlePayClicked = this.handlePayClicked.bind(this);
        this.toggleHistoryPanel = this.toggleHistoryPanel.bind(this);
    }

    componentDidMount() {
        const qs = queryString.parse(this.props.location.search);
        if (qs.invoiceId) {
            this.startLoading();
            this.loadInvoice(qs.invoiceId)
            .then(() => this.markInvoiceViewed(qs.invoiceId))
            .finally(() => this.stopLoading());
        }
    }

    loadInvoice(id) {
        return this.invoiceProvider.getOne(id, ['Customer'])
            .then(response => this.screenApiResponse(response))
            .then(response => {
                if (response.status === 404) this.tryNavigate('/404');
                else return response;
            })
            .then(response => response && response.json())
            .then(invoice => {
                if (invoice.history.length > 0)
                    invoice.history = invoice.history.sort((a,b) => moment(b.eventDateTime).toDate() - moment(a.eventDateTime).toDate());
                this.setState({invoice:invoice});
            })
            .catch(error => this.handleApiRejection(error))
    }

    markInvoiceViewed(id) {
        return this.invoiceProvider.markViewed(id)
        .then(response => this.screenApiResponse(response))
        .catch(error => this.handleApiRejection(error));
    }

    numberLineItems(lineItems) {
        var nextNumber = 1;
        for (var i=0;i<lineItems.length;i++) {
            if (lineItems[i].itemType === InvoiceLineItemTypeEnum.Normal || lineItems[i].itemType === InvoiceLineItemTypeEnum.ManualEntry || lineItems[i].itemType === InvoiceLineItemTypeEnum.Custom) {
                lineItems[i].lineItemNumber = nextNumber++;
            } else {
                lineItems[i].lineItemNumber = 0;
            }
        }
        return lineItems;
    }

    handlePrintClicked() {
        window.print();
    }

    handlePayClicked() {
        this.tryNavigate('/customer/payments');
    }

    renderInvoiceIdMissing() {
        return (
            <div className="animated fadeIn">
                <p>Invoice id not provided</p>
            </div>
        )
    }

    toggleHistoryPanel(e) {
        e.preventDefault();
        this.setState({historyPanelIsExpanded: !this.state.historyPanelIsExpanded});
    }
  
    render() {
        if (this.isLoading()) return this.renderLoading();
        if (!this.state.invoice) return this.renderInvoiceIdMissing();
        let invoice = this.state.invoice;
        let lineItems = this.numberLineItems(this.state.invoice.lineItems);
        let arLocation = this.state.invoice.receivablesAddress,
            invoiceLocation = this.state.invoice.invoiceAddress;
        return (
            <div className="animated fadeIn">
                <Card>
                    <CardHeader>
                        <div className="float-left">
                            <div>Invoice <strong>#{invoice.invoiceNumber}</strong></div>
                            <h3 className={invoice.paymentSubstatus === 'Failed' ? 'text-danger' : 'text-dark'}>Status: <strong>
                                {
                                    invoice.status === 'Paid' ? 'Paid on ' + moment(invoice.paymentDate).format('MM-dd-yyyy') : 
                                    invoice.status === 'PartiallyPaid' ? 'Partially paid on ' + moment(invoice.paymentDate).format('MM-dd-yyyy') : 
                                    'Open'
                                }
                                {
                                    invoice.paymentSubstatus === 'Failed' ? ' (Payment Failed)' :
                                    ''
                                }
                            </strong></h3>
                        </div>
                        <div className="float-right">
                            {
                                invoice.status !== 'Paid' ? (
                                    <Button color="success" size="sm" className="text-white mr-2" onClick={this.handlePayClicked}> Pay Now</Button>
                                ) : ''
                            }
                            <Button color="info" size="sm" className="text-white mr-2" onClick={this.handlePrintClicked}><i className="fa fa-print"/> Print</Button>
                        </div>
                    </CardHeader>
                    <CardBody id="printableInvoice">
                        {
                            invoice.paymentSubstatus === 'Failed' ?
                            (
                                <Row>
                                    <Col className="text-dark">
                                        <Alert color="danger" isOpen={true}>
                                            A recent attempt to debit for this invoice failed with reason: {invoice.paymentFailedReason}. Please click the 'Pay Now'
                                            button to update your payment information and re-attempt the payment.
                                        </Alert>
                                    </Col>
                                </Row>
                            ) : (<></>)
                        }
                        <Row className="mb-4">
                        <Col sm="4">
                            <h6 className="mb-3">From:</h6>
                            <div><strong>Noteable, LLC</strong></div>
                            <div>{arLocation.addressLine1}</div>
                            <div>{arLocation.city}, {arLocation.stateOrRegion} {arLocation.postalCode}</div>
                            <div>Email: {arLocation.emailAddress}</div>
                            <div>Phone: {arLocation.phoneNumber}</div>
                        </Col>
                        <Col sm="4">
                            <h6 className="mb-3">To:</h6>
                            <div><strong>{invoice.customer.name}</strong></div>
                            <div>{invoiceLocation.addressLine1}</div>
                            <div>{invoiceLocation.city}, {invoiceLocation.stateOrRegion} {invoiceLocation.postalCode}</div>
                            <div>Email: {invoice.customer.billingEmail}</div>
                            <div>Phone: {invoice.customer.billingPhone}</div>
                        </Col>
                        <Col sm="4">
                            <h6 className="mb-3">Details:</h6>
                            <div>Invoice <strong>#{invoice.invoiceNumber}</strong></div>
                            <div>Invoice Date: {moment(invoice.invoiceDate).format('MMMM DD, yyyy')}</div>
                            <div>Account Code: {invoice.customer.code}</div>
                        </Col>
                        </Row>
                        <Table striped responsive>
                            <thead>
                                <tr>
                                <th className="center">#</th>
                                <th>Item</th>
                                <th>Description</th>
                                <th className="center">Quantity</th>
                                <th className="right">Unit Cost</th>
                                <th className="right">Total</th>
                                <th className="center"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    lineItems.filter(li => li.itemType !== InvoiceLineItemTypeEnum.Custom).map((lineItem, index) => (
                                        <tr key={'lineItem_' + index}>
                                            {
                                                lineItem.itemType === InvoiceLineItemTypeEnum.Subheading ? (
                                                    <React.Fragment>
                                                        <td colSpan={7} className="bg-white">
                                                            <h5 className="my-0 py-0">{lineItem.description}</h5>
                                                        </td>
                                                    </React.Fragment>
                                                ) : lineItem.itemType === InvoiceLineItemTypeEnum.Subtotal ? (
                                                    <React.Fragment>
                                                        <td className="center">&nbsp;</td>
                                                        <td className="left font-weight-bold">{lineItem.itemName}</td>
                                                        <td className="left">&nbsp;</td>
                                                        <td className="center">&nbsp;</td>
                                                        <td className="right">&nbsp;</td>
                                                        <td className="right font-weight-bold">{formatUsCurrency(lineItem.amount)}</td>        
                                                        <td></td>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <td className="center">{lineItem.lineItemNumber}</td>
                                                        <td className="left">{lineItem.itemName}</td>
                                                        <td className="left">{lineItem.description}</td>
                                                        <td className="center">{lineItem.quantity}</td>
                                                        <td className="right">{formatUsCurrency(lineItem.unitCost)}</td>
                                                        <td className="right">{formatUsCurrency(lineItem.amount)}</td>        
                                                        <td></td>
                                                    </React.Fragment>
                                                )
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                        <Row>
                            <Col lg="4" sm="5">
                            </Col>
                            <Col lg="4" sm="5" className="ml-auto">
                                <Table className="table-clear">
                                    <tbody>
                                        <tr>
                                            <td className="left"><strong>Subtotal</strong></td>
                                            <td className="right"><strong>{formatUsCurrency(invoice.subtotal)}</strong></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Table>
                                    <tbody>
                                        {
                                            lineItems.filter(li => li.itemType === InvoiceLineItemTypeEnum.Custom).map((lineItem, index) => (
                                                <tr key={'lineItem_' + index}>
                                                    {
                                                        <React.Fragment>
                                                            <td className="center">{lineItem.lineItemNumber}</td>
                                                            <td className="left">{lineItem.itemName}</td>
                                                            <td className="left">{lineItem.description}</td>
                                                            <td className="center">{lineItem.quantity}</td>
                                                            <td className="right">{formatUsCurrency(lineItem.unitCost)}</td>
                                                            <td className="right">{formatUsCurrency(lineItem.amount)}</td>        
                                                        </React.Fragment>
                                                    }
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4" sm="5">
                                <div dangerouslySetInnerHTML={ {__html: invoice.invoiceText } }></div>
                            </Col>
                            <Col lg="4" sm="5" className="ml-auto">
                                <Table className="table-clear">
                                    <tbody>
                                        <tr>
                                            <td className="left"><h4>Invoice Total</h4></td>
                                            <td className="right"><h4>{formatUsCurrency(invoice.billedAmount)}</h4></td>
                                        </tr>
                                        <tr>
                                            <td className="left"><h5>Balance Due</h5></td>
                                            <td className="right"><h5>{formatUsCurrency(invoice.balance)}</h5></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                    <a href="#" onClick={this.toggleHistoryPanel}><i className={'text-info fa ' + (this.state.historyPanelIsExpanded ? 'fa-chevron-down' : 'fa-chevron-right')}/> Show Invoice History</a>                                    
                            </Col>
                        </Row>
                        <Collapse isOpen={this.state.historyPanelIsExpanded}>
                            {
                                invoice.history.length > 0 ? (
                                    <Timeline>  
                                        {
                                            invoice.history.map((event, index) => 
                                            (
                                                <React.Fragment key={index}>
                                                    <TimelineEvent title={event.eventDescription} createdAt={<TimeAgo date={event.eventDateTime} />} icon={<i className={event.icon} />} iconColor={'#63c2de'}/>
                                                </React.Fragment>
                                            ))
                                        }          
                                    </Timeline>                                
                                ) : (
                                    <div className="text-muted font-italic">No history found for this invoice</div>
                                )
                            }
                        </Collapse>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

export default ViewInvoicePage;
