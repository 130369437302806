import ProviderBase from './ProviderBase';
const   BaseEndpoint = '/customercontract',
        RequestSigningUrlEndpoint = '/customercontract/{0}/sign',
        SigningCompleteEndpoint = '/customercontract/{0}/signingcomplete',
        FilesEndpoint = '/customercontract/{0}/baa',
        GenerateEndpoint = '/customercontract/{0}/generate',
        MarkSignedEndpoint = '/customercontract/{0}/marksigned';

class CustomerContractProvider extends ProviderBase {    
    constructor() {
        super(BaseEndpoint);
    }

    getOne(id, includes) {
        return this._getOne(id, includes);
    }

    getSigningUrl(id) {
        return this.api.get(RequestSigningUrlEndpoint.format(id));
    }

    completeSigning(id) {
        return this.api.post(SigningCompleteEndpoint.format(id));
    }

    uploadFile(id, fileData) {
        return this.api.postFile(FilesEndpoint.format(id), fileData);
    }

    downloadFile(id) {
        return this.api.get(FilesEndpoint.format(id));
    }

    generate(id) {
        return this.api.post(GenerateEndpoint.format(id));
    }

    markSigned(id) {
        return this.api.put(MarkSignedEndpoint.format(id));
    }
}

export default CustomerContractProvider;