import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/salesperson',
        AccountsEndpoint = '/salesperson/{0}/accounts',
        PerformanceReportEndpoint = '/salesperson/{0}/performance-report';

class SalespersonProvider extends ProviderBase {    
    constructor() {
        super(BaseEndpoint);
    }

    getOne(id, includes) {
        return this._getOne(id, includes);
    }

    getAll(filter) {
        return this._getAll(filter);
    }

    post(data) {
        return this._post(data);
    }

    put(id, data) {
        return this._put(id, data);
    }

    delete(id) {
        return this._delete(id);
    }

    getPerformanceReport(id) {
        return this.api.get(PerformanceReportEndpoint.format(id));
    }

    postAccounts(id, customerIds) {
        return this.api.post(AccountsEndpoint.format(id), JSON.stringify({customerIds:customerIds}));
    }
}

export default SalespersonProvider;