import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
} from 'reactstrap';

const propTypes = {
    target: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    bodyClasses: PropTypes.string,
    windowTitle: PropTypes.string,
}

class PrintButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false,
        }
    }

    handleClicked() {
        var content = document.getElementById(this.props.target).innerHTML;
        var a = window.open('','',"height={0}, width={1}".format(this.props.height, this.props.width));
        a.document.write('<html>');
        a.document.write('<head>');
        a.document.write('<title>' + this.props.windowTitle + '</title>')
        a.document.write('<link rel=stylesheet href=https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css rel=nofollow integrity=sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm crossorigin=anonymous>')
        a.document.write('</head>');
        a.document.write("<body class={0}>".format(this.props.bodyClasses));
        a.document.write(content);
        a.document.write('</body>');
        a.document.write('</html>');
        a.document.close();
        setTimeout(() => a.print(), 1000);
    }

    render() {
        return (
            <Button color={this.props.color ? this.props.color : 'primary'} className={this.props.className} onClick={this.handleClicked.bind(this)}>
                <i className="fa fa-print mr-1"/> {this.props.label ? this.props.label : 'Print'}
            </Button>
        )
    }
}

PrintButton.propTypes = propTypes;

export default PrintButton;