const IncidentStatusEnum = {    
    New: 'New',
    Responded: 'Responded',
    Assigned: 'Assigned',
    AwaitingClarification: 'Awaiting Clarification',
    AwaitingRelease: 'Awaiting Release',
    AwaitingDev: 'Awaiting Dev',
    Resolved: 'Resolved',
    Confirming: 'Confirming',
    Closed: 'Closed',
    Aging: 'Aging',
    Reopened: 'Reopened',
    MeetingForScreenshare: 'Meeting For Screenshare',
}

export default IncidentStatusEnum;