import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/rapid-enroll',
        AgreementEndpoint = '/rapid-enroll/{0}/agreement',
        PaymentEndpoint = '/rapid-enroll/{0}/payment';

class RapidEnrollmentProvider extends ProviderBase {    
    constructor() {
        super(BaseEndpoint);
    }

    getEnrollmentAgreement(id) {
        return this.api.get(AgreementEndpoint.format(id));
    }

    signEnrollmentAgreement(id, requestData) {
        console.log(requestData);
        return this.api.post(AgreementEndpoint.format(id), JSON.stringify(requestData));
    }

    savePaymentProfile(id, paymentData) {
        return this.api.post(PaymentEndpoint.format(id), JSON.stringify(paymentData));
    }
}

export default RapidEnrollmentProvider;