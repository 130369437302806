import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';

const propTypes = {
    text: PropTypes.string,
};

class LoadingAnimation extends Component {
    render() {
        return (
            <Container>
                <Row className="justify-content-center">
                    <div className="sk-wave">
                    <div className="sk-rect sk-rect1"></div>&nbsp;
                    <div className="sk-rect sk-rect2"></div>&nbsp;
                    <div className="sk-rect sk-rect3"></div>&nbsp;
                    <div className="sk-rect sk-rect4"></div>&nbsp;
                    <div className="sk-rect sk-rect5"></div>
                    </div>
                </Row>
                <Row className="justify-content-center">
                    <p>{this.props.text}</p>
                </Row>
            </Container>
        );
    }
}

LoadingAnimation.propTypes = propTypes;

export default LoadingAnimation;