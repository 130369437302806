import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/user',
        AvatarEndpoint = '/user/{0}/avatar';

class UserProvider extends ProviderBase {    
    constructor() {
        super(BaseEndpoint);
    }

    getOne(id, includes) {
        return this._getOne(id, includes);
    }

    getAll(filter) {
        return this._getAll(filter);
    }

    put(id, data) {
        return this._put(id, data);
    }

    patch(id, data) {
        return this._patch(id, data);
    }

    delete(id) {
        return this._delete(id);
    }

    getAvatar(id) {
        return this.api.get(AvatarEndpoint.format(id));
    }

    setAvatar(id, avatarData) {
        return this.api.patch(AvatarEndpoint.format(id), JSON.stringify(avatarData));
    }
}

export default UserProvider;