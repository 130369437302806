import moment from 'moment';

export const BankAccountType = {
    None: "None",
    Checking: "Checking",
    Savings: "Savings"
}

export function getCcExpYears(yearCount = 10) {
    const years = []
    const dateStart = moment()
    const dateEnd = moment().add(10, 'y')
    while (dateEnd.diff(dateStart, 'years') >= 0) {
        years.push(dateStart.format('YYYY'))
        dateStart.add(1, 'year')
    }
    return years
}