import React, { Component } from 'react';
import { 
      Button, Card, CardBody, Col, Collapse, Container, Form, FormFeedback, FormGroup, FormText,
      Input, Label, InputGroup, InputGroupAddon, InputGroupText, Row, Alert } from 'reactstrap';
import logo from '../../../assets/img/brand/logo.png'
import { UserInvitationProvider } from '../../../providers';
import FormValidator from '../../../services/FormValidator';
import 'spinkit/css/spinkit.css';
import queryString from 'query-string';
import passwordValidator from 'password-validator';
import PalladientComponent from '../../../PalladientComponent';

const logoStyle = {
    height: '50px',
    textAlign: 'center',
    marginBottom: '15px'
}

class UserInvitationPage extends PalladientComponent {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
          {
            field: 'firstName',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please enter your first name'
          },
          {
            field: 'lastName',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please enter your last name'
          },
          {
            field: 'password',
            method: this.passwordStrength,
            validWhen: true,
            message: 'Please choose a stronger password'
          },
          {
            field: 'passwordConfirm',
            method: 'isEmpty',
            validWhen: false,
            message: 'You must re-type your password here'
          },
          {
            field: 'passwordConfirm',
            method: this.passwordMatch, // custom function
            validWhen: true,
            message: 'The passwords do not match'
          }
        ]);
    
        this.defineState({
          formVisible: false,
          formEnabled: true,
          buttonText: "Continue to Dashboard",
          firstName: "",
          lastName: "",
          password: "",
          passwordConfirm: "",
          completed: false,
          validation: this.validator.valid(),
        });

        this.submitted = false;
        this.userInvitationProvider = new UserInvitationProvider();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    passwordMatch = (confirmation, state) => (state.password === confirmation);

    passwordStrength = (password) => {
      var schema = new passwordValidator();
      schema
        .is().min(8)
        .is().max(100)
        .has().uppercase()
        .has().lowercase()
        .has().digits()
        .has().not().spaces();
      return schema.validate(password);
    };

    handleSubmit(event) {
      event.preventDefault();

      // validate
      var validation = this.validator.validate(this.state);
      if (validation.isValid) {
        this.disableForm();
        const qs = queryString.parse(this.props.location.search);
        this.userInvitationProvider.completeInvitation(qs.invitationId, qs.studioId,
            {
                userPassword: this.state.password,
                userFirstName: this.state.firstName,
                userLastName: this.state.lastName,                
            })
          .then(response => this.screenApiResponse(response))
          .then(data => data && this.handleCompleted(data))
          .catch(error => this.handleApiRejection(error));
      }
      else {
        this.setState({validation:validation, submitted:true});
      }      
    }

    handleCompleted(userData) {
      this.setState({ formVisible: false, completed: true });
      this.showAlert("Registration completed! Return to the sign-in page to login", "success");
    }
  
    disableForm() {
      this.setState({ formEnabled: false, alertVisible: false, alertText: "", buttonText: "Please wait..." });
    }
  
    enableForm() {
      this.setState({ formEnabled: true, buttonText: "Continue to Dashboard"});
    }

    componentDidMount() {
      this.startLoading();
      const qs = queryString.parse(this.props.location.search);
      if (!qs.invitationId) {
        this.showAlert('You must be here by mistake. You have not provided a confirmation token.');
        this.stopLoading();
      } else {
        this.userInvitationProvider.getInvitation(qs.invitationId, qs.studioId)
          .then(response => this.screenApiResponse(response))
          .then(response => response && response.json())
          .then(data => {
            if (!data) this.showAlert("The invitation was not found.", "danger");
            else {
              if (data.status === 'Expired') this.showAlert("The invitation is no longer valid.", "danger");
              else this.setState({formVisible: true});
            }
          })
          .catch(error => this.handleApiRejection(error))
          .finally(this.stopLoading());
      }
    }
  
    render() {
      if (this.isLoading()) return this.renderLoading();  
      let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation;
      return (
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="8">
                <Alert color={this.state.alertColor} isOpen={this.state.alertVisible} toggle={this.hideAlert}>{this.state.alertMessage}</Alert>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="8">
                <Card className="p-4">
                  <CardBody>
                    <Container>
                      <Row className="justify-content-center">
                        <img src={logo} style={logoStyle} alt="Logo" />
                      </Row>
                      <Row className="justify-content-center">
                        <h2>Noteable Account Management</h2>
                      </Row>
                      <Row>
                        <Col>
                          <hr className="text-dark"/>
                        </Col>
                      </Row>
                    </Container>
                    <Collapse isOpen={this.state.loading}>
                      <Container>
                        <Row className="justify-content-center">
                          <div className="sk-wave">
                            <div className="sk-rect sk-rect1"></div>&nbsp;
                            <div className="sk-rect sk-rect2"></div>&nbsp;
                            <div className="sk-rect sk-rect3"></div>&nbsp;
                            <div className="sk-rect sk-rect4"></div>&nbsp;
                            <div className="sk-rect sk-rect5"></div>
                          </div>
                        </Row>
                        <Row className="justify-content-center">
                          <p>Checking status of invitation...</p>
                        </Row>
                      </Container>
                    </Collapse>
                    <Collapse isOpen={this.state.formVisible}>
                      <Form onSubmit={this.handleSubmit} disabled={!this.state.formEnabled}>
                        <p className="text-muted">
                            Welcome to the Noteable Account Management and Billing Center.
                        </p>
                        <p className="text-muted">
                            Please provide the following information so we can finish setting up your account.
                        </p>
                        <FormGroup row>                                        
                            <Col xs={6}>
                                <Label>First Name</Label>
                                <InputGroup controlid="firstName">
                                    <Input type="text" id="firstName" placeholder="First" value={this.state.firstName} onChange={this.handleChange} invalid={validation.firstName.isInvalid}/>
                                    <FormFeedback valid={false}>{validation.firstName.message}</FormFeedback>
                                </InputGroup>
                            </Col>
                            <Col xs={6}>
                                <Label>Last Name</Label>
                                <InputGroup controlid="lastName">
                                    <Input type="text" id="lastName" placeholder="Last" value={this.state.lastName} onChange={this.handleChange} invalid={validation.lastName.isInvalid}/>
                                    <FormFeedback valid={false}>{validation.lastName.message}</FormFeedback>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <p className="text-muted">
                            In order to protect your privacy, we insist on the use of
                            a strong password. Therefore, make sure the password you choose is at least
                            8 alpha-numeric characters long, contains a mixture of upper and lower case
                            and at least one number.
                        </p>
                        <FormGroup>
                          <InputGroup controlid="password" className="mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="icon-lock"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input type="password" id="password" placeholder="New Password" value={this.state.password} onChange={this.handleChange} invalid={validation.password.isInvalid}/>
                              <FormFeedback valid={false}>{validation.password.message}</FormFeedback>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup controlid="passwordConfirm" className="mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="icon-lock"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input type="password" id="passwordConfirm" placeholder="Re-enter New Password" value={this.state.passwordConfirm} onChange={this.handleChange} invalid={validation.passwordConfirm.isInvalid}/>
                              <FormFeedback valid={false}>{validation.passwordConfirm.message}</FormFeedback>
                          </InputGroup>
                        </FormGroup>
                        <Row>
                          <Col xs="12">
                            <Button color="primary" block>{this.state.buttonText}</Button>
                          </Col>
                        </Row>
                      </Form>
                    </Collapse>
                    <Collapse isOpen={this.state.completed}>
                        <Container>
                            <Row>
                                <h1>That's it!</h1>
                                <p className="text=muted">
                                    Your account is setup. Return to the sign-in page and login with your email and password.
                                </p>
                            </Row>
                        </Container>
                    </Collapse>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content-center">
                <Button color="link" className="px-0" href="/#/signin">Go to sign-in page</Button>
            </Row>
          </Container>
        </div>
      );
    }
}

export default UserInvitationPage;
