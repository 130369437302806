import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/monthlysales',
        GenerateForMonthEndpoint = '/monthlysales/generate/formonth',
        GenerateFromMonthEndpoint= '/monthlysales/generate/frommonth';

class MonthlySalesReportProvider extends ProviderBase {    
    constructor() {
        super(BaseEndpoint);
    }

    getAll(filter) {
        return this._getAll(filter);
    }

    post(data) {
        return this._post(data);
    }

    put(id, data) {
        return this._put(id, data);
    }

    generateForMonth(month, year) {
        return this.api.post(GenerateForMonthEndpoint, JSON.stringify({month: month, year: year}));
    }

    generateFromMonth(month, year) {
        return this.api.post(GenerateFromMonthEndpoint, JSON.stringify({month: month, year: year}));
    }
}

export default MonthlySalesReportProvider;