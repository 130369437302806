import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/tenantusagedata',
        ForMonthEndpoint = '/tenantusagedata/month/{0}/year/{1}',
        CalculatedRevenueEndpoint = '/tenantusagedata/revenue/month/{0}/year/{1}';

class TenantUsageDataProvider extends ProviderBase {    
    constructor() {
        super(BaseEndpoint);
    }

    getForMonth(month, year) {
        return this.api.get(ForMonthEndpoint.format(month, year));
    }

    getCalculatedRevenueForMonth(month, year) {
        return this.api.get(CalculatedRevenueEndpoint.format(month, year));
    }
}

export default TenantUsageDataProvider;