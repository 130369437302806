const DefaultGuid = '00000000-0000-0000-0000-000000000000';

String.prototype.format = String.prototype.f = function() {
    var s = this,
        i = arguments.length;

    while (i--) {
        s = s.replace(new RegExp('\\{' + i + '\\}', 'gm'), arguments[i]);
    }
    return s;
};

String.prototype.truncateWithEllipsis = String.prototype.f = function() {        
    var s = this,
        len = arguments[0];
    return s.length > len ? `${s.substring(0, len)}...` : s;
};


String.prototype.stripHtml = String.prototype.f = function() {
    var s = this;
    var doc = new DOMParser().parseFromString(s, 'text/html');
    return doc.body.textContent || "";    
}

String.prototype.isDefaultGuid = String.prototype.f = function() {
    var s = this;
    return s == DefaultGuid;
}

String.prototype.isNumeric = String.prototype.f = function() {
    var str = this;
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

String.prototype.base64ToBlob = String.prototype.f = function() {
    const base64 = btoa(this);
    const contentType = arguments[0]
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
}
