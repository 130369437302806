import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/telehealth',
        ProvisionEndpoint = '/telehealth/{0}/provision',
        ProvisionV2Endpoint = '/telehealth/{0}/provision/v2';

class TelehealthProvisioningProvider extends ProviderBase {    
    constructor() {
        super(BaseEndpoint);
    }

    getProvisioningStatuses(customerId) {
        return this.api.get(ProvisionEndpoint.format(customerId));
    }

    toggleForMany(customerId, userIds, toggle) {
        const requestData = {
            noteableUserIds: userIds,
            toggle: toggle,
        };

        return this.api.post(ProvisionEndpoint.format(customerId), JSON.stringify(requestData));
    }

    toggleForManyV2(customerId, userIds, toggle) {
        const requestData = {
            noteableUserIds: userIds,
            toggle: toggle,
        };
        console.log(requestData);
        return this.api.post(ProvisionV2Endpoint.format(customerId), JSON.stringify(requestData));
    }
}

export default TelehealthProvisioningProvider;