import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/customer',
        CurrentContractEndpoint = '/customer/{0}/contract/current',
        EnrollmentEndpoint = '/customer/{0}/enrollment',
        UsageEndpoint = '/customer/{0}/usage/month/{1}/year/{2}',
        SubscriptionSummaryEndpoint = '/customer/{0}/subscriptionsummary',
        CreateContractEndpoint = '/customer/{0}/contract',
        BySecurityZoneIdEndpoint = '/customer/byzone/{0}',
        PaymentProfileEndpoint = '/customer/{0}/payment',
        PaymentProfileSyncEndpoint = '/customer/{0}/payment/sync',
        PaymentProfileChargeEndpoint = '/customer/{0}/payment/charge',
        PaymentInfoClearEndpoint = '/customer/{0}/payment/clear',
        SetAccountCodeEndpoint = '/customer/{0}/payment/code',
        AutoPayEndpoint = '/customer/{0}/payment/auto',
        SubscriptionEndpoint = '/customer/{0}/subscription',
        SetPrimarySubscriptionEndpoint = '/customer/{0}/subscription/{1}/setprimary',
        GetPrimarySubscriptionEndpoint = '/customer/{0}/subscription/primary',
        BillingLocationEndpoint = '/customer/{0}/location/billing',
        InvoiceLocationEndpoint = '/customer/{0}/location/invoice',
        InvoicesEndpoint = '/customer/{0}/invoices',
        SalesSummaryEndpoint = '/customer/{0}/salessummary',
        TrainingVideosEndpoint = '/customer/{0}/trainingvideo',
        TrainingVideoEndpoint = '/customer/{0}/trainingvideo/{1}',
        BillingContactsEndpoint = '/customer/billingcontactlist',
        SetupInvoiceEndpoint = '/customer/{0}/invoices/setup',
        CreateTenantEndpoint = '/customer/{0}/tenant',
        UploadTenantLogoEndpoint = '/customer/{0}/tenant/logo',
        PaymentHistoryEndpoint = '/customer/{0}/paymenthistory',
        NotesEndpoint = '/customer/{0}/notes',
        NoteEndpoint = '/customer/{0}/notes/{1}',
        FilesEndpoint = '/customer/{0}/files',
        FileEndpoint = '/customer/{0}/files/{1}',
        ArchiveEndpoint = '/customer/{0}/archive',
        LocationsEndpoint = '/customer/{0}/location',
        PaymentFailedEndpoint = '/customer/{0}/notify-payment-failed';

class CustomerProvider extends ProviderBase {    
    constructor() {
        super(BaseEndpoint);
    }

    getOne(id, includes) {
        return this._getOne(id, includes);
    }

    getAll(filter) {
        return this._getAll(filter);
    }

    post(data) {
        return this._post(data);
    }

    put(id, data) {
        return this._put(id, data);
    }

    patch(id, data) {
        return this._patch(id, data);
    }

    delete(id) {
        return this._delete(id);
    }

    archive(id) {
        return this.api.delete(ArchiveEndpoint.format(id));
    }

    getBySecurityZoneId(securityZoneId, includes) {
        var url = this.buildGetOneUrl(BySecurityZoneIdEndpoint.format(securityZoneId), includes);
        return this.api.get(url);
    }

    getCurrentContract(id) {
        return this.api.get(CurrentContractEndpoint.format(id));
    }

    getEnrollment(id) {
        return this.api.get(EnrollmentEndpoint.format(id));
    }

    getUsage(id, month, year) {
        return this.api.get(UsageEndpoint.format(id, month, year));
    }

    getSalesSummary(id) {
        return this.api.get(SalesSummaryEndpoint.format(id));
    }

    addSubscription(id, subscriptionData)  {
        return this.api.post(SubscriptionEndpoint.format(id), JSON.stringify(subscriptionData));
    }

    getSubscriptionSummary(id) {
        return this.api.get(SubscriptionSummaryEndpoint.format(id));
    }

    createContract(id, contractData) {
        return this.api.post(CreateContractEndpoint.format(id), JSON.stringify(contractData));
    }

    savePaymentProfile(id, paymentData) {
        return this.api.post(PaymentProfileEndpoint.format(id), JSON.stringify(paymentData));
    }

    getPaymentProfile(id) {
        return this.api.get(PaymentProfileEndpoint.format(id));
    }

    setAccountCode(id, accountCode) {
        return this.api.put(SetAccountCodeEndpoint.format(id), JSON.stringify({accountCode:accountCode}));
    }

    syncPaymentProfileFromGateway(id) {
        return this.api.get(PaymentProfileSyncEndpoint.format(id));
    }

    chargePaymentProfile(id, chargeDetails) {
        return this.api.post(PaymentProfileChargeEndpoint.format(id), JSON.stringify(chargeDetails));
    }

    clearPaymentInfo(id) {
        return this.api.put(PaymentInfoClearEndpoint.format(id));
    }

    getPaymentHistory(id, filter) {
        let uri = PaymentHistoryEndpoint.format(id);
        if (filter) {
            uri += '?filter=' + encodeURIComponent(filter);
        }
        return this.api.get(uri);
    }

    toggleAutoPay(id) {
        return this.api.put(AutoPayEndpoint.format(id));
    }

    getBillingLocation(id) {
        return this.api.get(BillingLocationEndpoint.format(id));
    }

    getInvoiceLocation(id) {
        return this.api.get(InvoiceLocationEndpoint.format(id));
    }

    setInvoiceLocation(id, locationData) {
        return this.api.post(InvoiceLocationEndpoint.format(id), JSON.stringify(locationData));
    }

    getAllLocations(id, filter) {
        let uri = LocationsEndpoint.format(id);
        if (filter) {
            uri += '?filter=' + encodeURIComponent(filter);
        }
        return this.api.get(uri);
    }

    getInvoices(id, filter) {        
        var uri = InvoicesEndpoint.format(id);
        if (filter) {
            uri +=  '?filter=' + encodeURIComponent(filter);
        }
        return this.api.get(uri);
    }

    createSetupInvoice(id, invoiceData) {
        return this.api.post(SetupInvoiceEndpoint.format(id), JSON.stringify(invoiceData));
    }

    postTrainingVideo(id, videoData) {
        return this.api.post(TrainingVideosEndpoint.format(id), JSON.stringify(videoData));
    }

    putTrainingVideo(id, videoId, videoData) {
        return this.api.put(TrainingVideoEndpoint.format(id, videoId), JSON.stringify(videoData));
    }

    getAllTrainingVideos(id) {
        return this.api.get(TrainingVideosEndpoint.format(id));
    }

    getAllBillingContacts() {
        return this.api.get(BillingContactsEndpoint);
    }

    notifyPaymentFailed(id) {
        return this.api.post(PaymentFailedEndpoint.format(id));
    }

    createTenant(id, tenantData) {
        return this.api.post(CreateTenantEndpoint.format(id), JSON.stringify(tenantData));
    }

    uploadTenantLogo(id, imageFile) {
        return this.api.postFile(UploadTenantLogoEndpoint.format(id), imageFile);
    }

    setPrimarySubscription(id, subscriptionId) {
        return this.api.put(SetPrimarySubscriptionEndpoint.format(id, subscriptionId));
    }

    getPrimarySubscription(id) {
        return this.api.get(GetPrimarySubscriptionEndpoint.format(id));
    }

    getNotes(id, relevant) {
        let url = NotesEndpoint.format(id);
        if (relevant) url += '?relevant=true';
        return this.api.get(url)
    }

    getNote(id, noteId) {
        return this.api.get(NoteEndpoint.format(id, noteId));
    }

    postNote(id, noteData) {
        return this.api.post(NotesEndpoint.format(id), JSON.stringify(noteData));
    }

    putNote(id, noteId, noteData) {
        return this.api.put(NoteEndpoint.format(id, noteId), JSON.stringify(noteData));
    }

    deleteNote(id, noteId) {
        return this.api.delete(NoteEndpoint.format(id, noteId));
    }

    getFiles(id) {
        return this.api.get(FilesEndpoint.format(id));
    }
    
    uploadFile(id, fileData) {
        return this.api.postFile(FilesEndpoint.format(id), fileData);
    }

    downloadFile(id, fileId) {
        return this.api.get(FileEndpoint.format(id, fileId));
    }

    deleteFile(id, fileId) {
        return this.api.delete(FileEndpoint.format(id, fileId));
    }
}

export default CustomerProvider;