import ProviderBase from './ProviderBase';

const BaseEndpoint = '/customernote';

class CustomerNotesProvider extends ProviderBase {    
    constructor() {
        super(BaseEndpoint);
    }

    getOne(id, includes) {
        return this._getOne(id, includes);
    }

    getForMonth(month, year) {
        const url = BaseEndpoint + ('?month={0}&year={1}'.format(month, year));
        return this.api.get(url);
    }
}

export default CustomerNotesProvider;
