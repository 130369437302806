import ProviderBase from './ProviderBase';

const BaseEndpoint = '/customersummary';

class CustomerSummaryProvider extends ProviderBase {    
    constructor() {
        super(BaseEndpoint);
    }

    getAll(filter) {
        return this._getAll(filter);
    }
}

export default CustomerSummaryProvider;