import Config from '../config';
import cookie from 'react-cookies';

export const AuthorizationEndpoint = '/authorization/token';
export const PasswordRecoveryEndpoint = '/authorization/recovery';
export const SessionUserEndpoint = '/user/me';
export const ProductsEndpoint = '/product';
export const ProductEndpoint = '/product/{0}';
export const ProductImageEndpoint = '/product/{0}/image';
export const CustomersEndpoint = '/customer';
export const CustomerSummariesEndpoint = '/customersummary';
export const CustomerEndpoint = '/customer/{0}';
export const CustomerByZoneEndpoint = '/customer/byzone/{0}';
export const CustomerUsageEndpoint = '/customer/{0}/usage/month/{1}/year/{2}';
export const CustomerBillsEndpoint = '/customer/{0}/bills';
export const CustomerSubscriptionsEndpoint = '/customer/{0}/subscriptions';
export const CustomerSubscriptionSummariesEndpoint = '/customer/{0}/subscriptionsummary';
export const EnrollmentsEndpoint = '/enrollment';
export const EnrollmentEndpoint = '/enrollment/{0}';
export const CustomerEnrollmentEndpoint = '/enrollment/{0}';
export const EnrollmentByCustomerEndpoint = '/customer/{0}/enrollment';
export const CustomerCurrentContractEndpoint = '/customer/{0}/contract/current';
export const SignatureUrlRequestEndpoint = '/customercontract/{0}/sign';
export const SigningCompletedEndpoint = '/customercontract/{0}/signingcomplete';
export const UserAvatarEndpoint = '/user/{0}/avatar';
export const CustomerBillingLocationEndpoint = '/customer/{0}/location/billing';
export const CustomerPaymentProfileEndpoint = '/customer/{0}/payment';
export const UsersEndpoint = '/user';
export const SessionAvatarEndpoint = '/user/me/avatar';

export class NoteabillApi {
    constructor() {
        this.apiBaseUrl = Config.ApiBaseUrl;
    }

    doFetch(path, method, headers, body) {
        var startTimestamp = new Date().getTime(); 
        var url = this.apiBaseUrl + path;
        var token = cookie.load('token');
        if (token) headers.append('Authorization', 'token ' + token);
        console.log("calling server: " + method + " - " + url);
        return fetch(url, {method: method, headers: headers, body: body})
        .then(function(response) {
            var endTimestamp = new Date().getTime();
            var timespan = endTimestamp - startTimestamp;
            console.log("(" + timespan + "ms) " + "response for " + method + " - " + url + ": " + response.status + " - " + response.statusText);
            return response;
        });
    }

    post(path, body) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return this.doFetch(path, 'post', headers, body);
    }

    postImage(path, image, imageType) {
        var headers = new Headers();
        headers.append('Content-Type', 'image/' + imageType);
        return this.doFetch(path, 'post', headers, image);
    }

    put(path, body) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return this.doFetch(path, 'put', headers, body);
    }

    patch(path, body) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return this.doFetch(path, 'patch', headers, body);
    }

    get(path) {
        return this.doFetch(path, 'get', new Headers(), null);
    }

    delete(path) {
        return this.doFetch(path, 'delete', new Headers(), null);
    }
}