import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import LoadingAnimation from '../LoadingAnimation';

const propTypes = {
    children: PropTypes.node.isRequired,
    loading: PropTypes.bool,
};
  
class ContentLoader extends Component {
    render() {
        return (
            <div className="animated fadeIn">
            <Collapse isOpen={this.props.loading}>
                <LoadingAnimation text="Loading..."/>
            </Collapse>
            <Collapse isOpen={!this.props.loading}>
                {this.props.children}
            </Collapse>
        </div>
        );
    }
}

ContentLoader.propTypes = propTypes;

export default ContentLoader;

