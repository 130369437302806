import ProviderBase from './ProviderBase';
const   BaseEndpoint = '/customer-agreement-template',
        VersionEndpoint = '/customer-agreement-template/version',
        LatestForCustomerEndpoint = '/customer-agreement-template/forcustomer/{0}/latest',
        AcceptedByCustomerEndpoint = '/customer-agreement-template/forcustomer/{0}/accepted';

class CustomerAgreementTemplateProvider extends ProviderBase {    
    constructor() {
        super(BaseEndpoint);
    }

    postVersion() {
        return this.api.post(VersionEndpoint);
    }

    getLatestVersion() {
        return this.api.get(VersionEndpoint);
    }

    getLatestVersionForCustomer(customerId) {
        return this.api.get(LatestForCustomerEndpoint.format(customerId));
    }

    getAcceptedVersionForCustomer(customerId) {
        return this.api.get(AcceptedByCustomerEndpoint.format(customerId));
    }

    acceptLatestVersionForCustomer(customerId) {
        return this.api.post(AcceptedByCustomerEndpoint.format(customerId));
    }
}

export default CustomerAgreementTemplateProvider;