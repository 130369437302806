import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/usageimportevent',
        MostRecentEndpoint = '/usageimportevent/mostrecent';

class UsageImportEventProvider extends ProviderBase {    
    constructor() {
        super(BaseEndpoint);
    }

    post(data) {
        return this._post(data);
    }

    getOne(id, includes) {
        return this._getOne(id, includes);
    }

    getMostRecent() {
        return this.api.get(MostRecentEndpoint);
    }
}

export default UsageImportEventProvider;