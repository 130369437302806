const workNav = {
  items: [
    {
      name: 'Home',
      url: '/admin',
      icon: 'fa fa-home',
    },
    {
      name: 'Customers',
      url: '/admin/customers',
      icon: 'fa fa-handshake-o',
    },
    {
      name: 'Products',
      url: '/admin/products',
      icon: 'fa fa-shopping-bag',
    },
    {
      name: 'Internal Users',
      url: '/admin/users',
      icon: 'fa fa-user',
    },
    {
      name: 'Sales',
      url: '/admin/usage/browse',
      icon: 'fa fa-dashboard',
      children: [
        {
          name: 'Manage Invoices',
          url: '/admin/usage/invoices',
          icon: 'fa fa-usd',
        },
        {
          name: 'Import Usage',
          url: '/admin/usage/import',
          icon: 'fa fa-download'
        },
        {
          name: 'Browse Usage',
          url: '/admin/usage/browse',
          icon: 'fa fa-list',
        }
      ]
    },
    // {
    //   name: 'Communications',
    //   url: '/admin/communications/home',
    //   icon: 'fa fa-bullhorn',
    //   children: [
    //     {
    //       name: 'Product Releases',
    //       url: '/admin/communications/releases',
    //       icon: 'fa fa-refresh',
    //     },
    //     {
    //       name: 'News Bulletins',
    //       url: '/admin/communications/bulletins',
    //       icon: 'fa fa-newspaper-o'
    //     }
    //   ]
    // }
  ]
}

export default workNav;
