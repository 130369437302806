import React, { Component } from 'react';
import { 
  Button, Card, CardBody, CardGroup, Col, Container, Form, FormFeedback, 
  FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, 
  Row, Alert, Collapse } from 'reactstrap';
import logo from '../../../assets/img/brand/logo.png'
import { NoteabillApi, AuthorizationEndpoint, SessionAvatarEndpoint } from '../../../services/NoteabillApi';
import cookie from 'react-cookies';
import FormValidator from '../../../services/FormValidator';
import { LoadingAnimation } from '../../../components';

const AuthEndpoint = AuthorizationEndpoint;

const logoStyle = {
  height: '80px',
  textAlign: 'center',
  marginBottom: '15px'
}

const rememberMeStyle = {
  marginLeft: '20px'
}

class SigninPage extends Component {
  
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: 'email',
        method: 'isEmpty',
        validWhen: false,
        message: 'Email is required'
      },
      {
        field: 'email',
        method: 'isEmail',
        validWhen: true,
        message: 'Please enter a valid email'
      },
      {
        field: 'password',
        method: 'isEmpty',
        validWhen: false,
        message: 'Password is required'
      }
    ]);

    this.state = {
      email: "",
      password: "",
      rememberMe: false,
      alertVisible: false,
      alertMessage: "",
      alertColor: "info",
      formEnabled: true,
      buttonText: "Sign in",
      requestedPath: "",
      validation: this.validator.valid()
    }

    this.submitted = false;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }  

  handleChange = (event) => {
    if (event.target.id == 'rememberMe') {
      this.setState({rememberMe: event.target.checked})
    } else {
      this.setState({
        [event.target.id]: event.target.value
      });  
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    
    // validate
    const validation = this.validator.validate(this.state);
    this.setState({validation})
    this.submitted = true;

    if (validation.isValid) {
      // prepare request to the auth endpoint
      this.disableForm();
      var api = new NoteabillApi();
      api.post(AuthEndpoint, JSON.stringify({email: this.state.email, password: this.state.password}), false)
        .then(response => this.handleUnauthorized(response))
        .then(response => response && response.json())
        .then(data => data && this.completeLogin(data))
        .catch(error => this.handleReject(error));
    }
  }

  showAlert(message, color) {
    this.setState({ alertVisible: true, alertMessage: message, alertColor: color });
  }

  hideAlert() {
    this.setState({ alertVisible: false });
  }

  completeLogin(session) {  
    // handle "remember me" checkbox
    if (this.state.rememberMe) {
      // save email to local storage
      localStorage.setItem('email', this.state.email);
    } else {
      // remove email from local storage if it's there
      localStorage.removeItem('email');
    }
    var expire = new Date();
    expire.setTime(expire.getTime()+(30*60*1000));
    cookie.save('token', session.token, {path: '/', expires: expire});

    // fetch the user avatar
    var api = new NoteabillApi();
    api.get(SessionAvatarEndpoint)
      .then(response => response && response.json())
      .then(avatarData => {
        session.avatarImage = avatarData.avatarImage;
      })
      .finally(function() {
        // keep session data in local storage
        sessionStorage.setItem("session", JSON.stringify(session));
        var redirectOnLogin = this.props.location.state ? this.props.location.state.requestedPath : '/';
        this.props.history.push(redirectOnLogin);
      }.bind(this));
  }

  handleUnauthorized(response) {
    if (!response.ok) {
      if (response.status == 401) {
        this.showAlert("We could not log you in with that email and password. Please try again.", "danger");
      } else {
        this.showAlert("Hmm, something went wrong " + response.statusText, "danger");
      }
      this.enableForm();
    } else {
      return response;
    }
  }

  handleReject(error) {
    console.log(error);
    this.showAlert("An unexpected error occurred: " + error, "danger");
    this.setState({ password: ""})
    this.enableForm();
  }

  disableForm() {
    this.setState({ formEnabled: false, buttonText: "Signing in...", alertVisible: false, alertText: "" });
  }

  enableForm() {
    this.setState({ formEnabled: true, buttonText: "Sign in" });
  }

  handleLogoutComplete() {
    cookie.remove('token');
    sessionStorage.removeItem('session');
    this.enableForm();
    this.showAlert("You have been logged out", "primary");
  }

  componentDidMount() {
    if (localStorage.getItem('email')) {
      this.setState({rememberMe: true, email: localStorage.getItem('email')});
    }
  }

  render() {
    let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation;
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="9">
              <Alert color={this.state.alertColor} isOpen={this.state.alertVisible} toggle={this.hideAlert}>{this.state.alertMessage}</Alert>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="9">
              <Card className="p-4">
                <CardBody>
                  <Container>
                    <Row className="justify-content-center">
                      <img src={logo} style={logoStyle} alt="Logo" />
                    </Row>
                    <Row className="justify-content-center">
                      <h3>Account Management and Billing Center</h3>
                    </Row>                    
                  </Container>
                  <hr/>
                  <Collapse isOpen={this.state.formEnabled}>
                    <Form onSubmit={this.handleSubmit} disabled={!this.state.formEnabled}>
                      <h1>Sign in</h1>
                      <p className="text-muted">Sign in to your account to continue. If you need access, contact your Noteable account administrator.</p>
                      <FormGroup>
                        <InputGroup controlid="email" className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-envelope"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input type="email" id="email" autoFocus placeholder="Email" autoComplete="email" value={this.state.email} onChange={this.handleChange} invalid={validation.email.isInvalid}/>
                          <FormFeedback valid={false}>{validation.email.message}</FormFeedback>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <InputGroup controlid="password" className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-lock"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input type="password" id="password" placeholder="Password" autoComplete="current-password" value={this.state.password} onChange={this.handleChange} invalid={validation.password.isInvalid}/>
                          <FormFeedback valid={false}>{validation.password.message}</FormFeedback>
                        </InputGroup>
                      </FormGroup>
                      <InputGroup controlid="rememberMe" className="mb-4" style={rememberMeStyle}>
                        <Input type="checkbox" id="rememberMe" checked={this.state.rememberMe} onChange={this.handleChange} />{' '} Remember me?
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button color="primary" className="px-4" disabled={!this.state.formEnabled}>{this.state.buttonText}</Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          <Button color="link" className="px-0" href="/#/forgotpassword">Forgot password?</Button>
                        </Col>
                      </Row>
                    </Form>
                  </Collapse>
                  <Collapse isOpen={!this.state.formEnabled}>
                    <LoadingAnimation text="Signing you in..."/>
                  </Collapse>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default SigninPage;
